import React, {useEffect} from 'react';

function Logout(props) {
  useEffect(()=>{
    localStorage.removeItem('access_token');
    window.location = "/"
  },[])
  return null;
}

export default Logout;
