import {useSettingsStore} from '../../store/settingsStore';
import React, {useMemo} from 'react';
import {localesOptions} from '../../supportedLanguages';
import Select from '../form/Select';
import uniqby from 'lodash.uniqby'
export default function AvailableLanguageSelector({value, style, onChange}){
  const supportedLanguages = useSettingsStore((state)=>state.settings.supportedLanguages)
  const options = useMemo(()=>{
    return supportedLanguages ? uniqby(supportedLanguages.map((item)=> {
      return localesOptions.find(({value})=>value === item)
    }).filter((i)=>i), (i)=>i.value) : []
  },[supportedLanguages])
  if(options.length > 1){
    return <Select
        style={style}
        options={options} value={value} onChange={onChange} />
  }else{
    return null;
  }
}
