import React, {useCallback, useMemo} from 'react';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import {
  FiChevronDown,
  FiDollarSign,
  FiGrid,
  FiHelpCircle, FiInstagram,
  FiMessageSquare,
  FiSettings,
  FiUmbrella,
  FiUser,
  FiShoppingBag,
} from 'react-icons/fi';
import {Collapse} from 'react-bootstrap';
import useToggle from '../hooks/useToggle';
import {
  AiOutlineFormatPainter,
  AiOutlineGift,
  AiOutlineGold,
    AiOutlineLineChart
} from 'react-icons/ai';
import {
  BsInputCursorText
} from 'react-icons/bs'
import NeedHelpSidebar from './NeedHelpSidebar';
import {useSettingsStore} from '../../store/settingsStore';
import {removeBilling} from '../../config';

function Sidebar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const siteId = useSettingsStore(useCallback((state)=>state.settings._id,[]))

  const allSidebarItems = useMemo(()=>{
    const items = [
      {
        name:'Dashboard',
        to:'/',
        icon:<FiGrid />
      },
      {
        name:'Customers',
        to:'/customers',
        icon:<FiUser />
      },
      {
        name:'Sales',
        to:'/sales',
        icon:<FiShoppingBag />
      },
      {
        name:'Loyalty',
        to:'/loyalty',
        icon:<AiOutlineGold />
      },
      {
        name:'Reviews',
        to:'/product-reviews',
        icon:<FiMessageSquare />
      },
      {
        name:'FAQ',
        to:'/faq',
        icon:<FiHelpCircle />
      },/*
      {
        name:'PWA',
        to:'/pwa',
        icon: <IoLogoPwa />
      },
      */
      {
        name:'Contact',
        to:'/contact',
        icon:<FiUmbrella />
      },
      {
        name:'Analytics',
        to:'/analytics',
        icon:<AiOutlineLineChart />
      },
      {
        name:'Billing',
        to:'/billing',
        icon:<FiDollarSign />
      },
      /*
       {
         name:'Notification Bar',
         to:'/notification-bar'
       },
       {
         name:'Slider',
         to:'/slider'
       },

       {
         name:'Visitor Analytics',
         to:'/analytics'
       },

       {
         name:'Geo Redirection',
         to:'/geo-redirect'
       },
       {
         name:'Popups',
         to:'/popups'
       },
       */
        /*
       {
         name:'Polls',
         to:'/poll'
       },
       {
         name:'Surveys',
         to:'/surveys'
       },
       {
         name:'Push Notifications',
         to:'/push'
       },
       {
         name:'Page speed optimizer',
         to:'/page-speed'
       },
       {
         name:'Social Proof',
         to:'/social-proof'
       },
       {
         name:'Security',
         to:'/security'
       },
       {
         name:'Badges',
         to:'/badges'
       },

       {
         name:'Up-sell',
         to:'/upsell'
       },
       {
         name:'SEO Audit',
         to:'/seo'
       },
       */
      {
        name:'Settings',
        to:'/settings',
        icon:<FiSettings />
      },
      {
        name:'Theme Editor',
        to:'/theme-editor',
        icon:<AiOutlineFormatPainter />
      },
      {
        name:'Rewards',
        to:'/rewards',
        icon:<AiOutlineGift />
      },
    ]
    items.push({
      name:'Instagram',
      to:'/instagram',
      icon: <FiInstagram />
    });
    items.push({
      name:'Forms',
      to:'/form',
      icon:<BsInputCursorText />
    })
    return items;
  },[])

  const setRoutes = useCallback((item) => allSidebarItems.find((i)=>i.to === item),[allSidebarItems])

  const sections = useMemo(()=>{
    return [
    {
      routes:['/'].map(setRoutes).filter((i)=>i)
    },
    {
      title:'Setup',
      routes:['/theme-editor','/instagram','/loyalty','/form','/other-widgets'].map(setRoutes).filter((i)=>i)
    },{
      title:'Manage',
      routes:['/customers','/sales','/rewards','/product-reviews','/faq'].map(setRoutes).filter((i)=>i)
    },
    {
      title:'Settings',
      routes:['/analytics','/settings', '/billing']
          .filter((i)=>i === "/billing" ? !removeBilling : true)
          .map(setRoutes).filter((i)=>i)
    }
  ]},[setRoutes])

  function onChange(e){
    navigate(e.target.value)
  }
  const value = useMemo(()=>{
    if(location) {
      const path = location.pathname;
      const match = allSidebarItems.find((i) => {
        const isExact = i.to === "/"
        if(isExact){
          return path === i.to
        }else{
          return path.startsWith(i.to)
        }
      })
      return match ? match.to : "/";
    }
  },[allSidebarItems, location])

  return (
      <>
      <div className={"d-none bg-light d-md-block border-end h-100"}>
        {
          sections.map((item, index)=>{
            return <RenderSidebarSection key={String(index)} title={item.title} routes={item.routes} />
          })
        }
        <NeedHelpSidebar />
      </div>
        <div className="d-block d-md-none p-2 bg-light">
          <select className="form-control" value={value} onChange={onChange}>
            {
              allSidebarItems.map((item)=>{
                return <option value={item.to} key={item.to}>{item.name}</option>
              })
            }
            <option value="/contact">Contact</option>
          </select>
        </div>
      </>
  );
}


function RenderSidebarSection({title, routes}){
  const [open, toggle] = useToggle(true)
  return <>
    {title ? <div onClick={toggle} className={"d-flex align-items-center justify-content-between w-100 rounded-0 btn bg-secondary text-light p-2"}>
      <span>{title}</span>
      <FiChevronDown style={{transition:'transform 250ms', transform:`rotate(${open? '0deg' : '-90deg'}`}}/>
    </div> : null }
    <Collapse in={open}>
      <div>
    {
      routes ? routes.map(({to, icon, name})=>{
        return  <NavLink to={to}
                         className={({isActive})=> `d-flex align-items-center p-3 border-bottom d-block text-decoration-none ${isActive ? 'text-primary':'text-secondary'}`} key={to}>
          {icon}
          <span className={"ms-1"}>{name}</span>
        </NavLink>

      }) : null
    }</div></Collapse>
  </>
}

export default Sidebar;
