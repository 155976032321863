import React, {useCallback, useMemo} from 'react';
import {nanoid} from '../../func';

function Radio({value, label, name, onChange}) {
  const id = useMemo(()=>{
    return nanoid()
  },[])
  const changed = useCallback((e)=>{
    onChange(name)
  },[name, onChange])
  return (
      <div className="form-check">
        <input className="form-check-input" type="radio"
               onChange={changed}
               value={value}
               checked={name === value}
               id={id} />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div>
  );
}

export default Radio;
