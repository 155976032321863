import React, {useEffect, useState} from 'react';
import {gqlQuery} from '../../../api';
import Skeleton from 'react-loading-skeleton'
import EmptyCard from './EmptyCard';
import RenderRating from '../../reviews/components/RenderRating';
import RenderCustomer from '../../reviews/components/RenderCustomer';
import {Link} from 'react-router-dom';
function RecentReviews(props) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  useEffect(()=>{
    setLoading(true)
    gqlQuery(`
      query ($limit: Int){
        reviews(limit: $limit){
          data {
            title
            description
            attachments{
              _id
              src
            }
            _id
            rating
            createdAt
            reply
            repliedAt
            customer {
                _id
                emailAddress
                firstName
                lastName
                profilePhotoUrl
           }
            product{
              name
            }
          }
        }      
      }
    `, {
      limit: 5
    }).then(({reviews}) =>{
      console.log(reviews)
      setLoading(false)
      setData(reviews.data);
    })
  },[])

  return (
      <>
        {
          loading ? <Skeleton count={5} height={36} /> : <ul className={'list-group list-group-flush'}>
            {
              data && data.length > 0 ? data.map((review)=>{
                return <Link to={"/product-reviews#"+review._id} className={"list-group-item list-group-item-action"}>
                    <div className="d-flex border-bottom pb-2 border-light align-items-center justify-content-between">
                      <small className={"text-muted"}>by <RenderCustomer customer={review.customer} /></small>
                      <RenderRating rating={review.rating}/>
                    </div>

                    {review.title ? <h6>{review.title}</h6> : null}
                    <div className={"pre-line"}>{review.description}</div>
                  {
                    review.reply ? <div className={"ms-2 mt-2 p-2 bg-light rounded border"}>
                      {review.reply}
                    </div> : null
                  }
                </Link>
              }) :  <EmptyCard />
            }
          </ul>
        }
      </>
  );
}




export default RecentReviews;
