import RenderCurrencyNumber from './RenderCurrencyNumber';
import DevOnly from './DevOnly';
import React from 'react';
import * as PropTypes from 'prop-types';

RenderDiscountValue.propTypes = {
  discountValue: PropTypes.any,
  discountType: PropTypes.any,
};

export function RenderDiscountValue({discountType,  freeProduct, discountValue}){
  switch (discountType){
    case "free_product":
      return freeProduct ? <span title={"Free product"}>{freeProduct.label}</span> : "Free product"
    case "free_shipping":
      return "Free Shipping"
    case "percentage":
      return `${discountValue}% off`
    case "fixed_amount":
      return <span>{<RenderCurrencyNumber value={discountValue} />} off</span>
    default:
      return <DevOnly object={{discountValue, discountType}}/>;
  }
}

export function RenderTaskType({item}){
  const {task_type, amount, order} = item
  switch (task_type){
    case "refer_a_friend":
      return `Referral reward ${order ? order.number : ''}`;
    case "place_an_order":
      return `Personal order ${order ? order.number: ''}`
    case "redeem_points":
      return `Points redeemed (${amount} points)`
    case "wallet_adjustment":
      return `Manual adjustment`
    case "birthday":
      return `Birthday reward`
    case "signup":
      return `Signup reward`
    case "post_a_review":
      return `Posted review`
    case "post_a_faq":
      return `Asked a question`
    case "social_follow":
      return `Followed on social media`
    case "social_share":
      return `Shared content on social media`
    case "accepts_marketing":
      return "Subscribed to newsletter"
    default:
      return null;
  }
}
