import {FaStar} from 'react-icons/fa';
import React from 'react';

const colors = {
  5:'#69B34C',
  4:'#ACB334',
  3:'#fab733',
  2:'#ff4e11',
  1:'#ff4e11'
}

function RenderRating({rating}){
  return <span className={"badge text-light d-inline-flex align-items-center"} style={{backgroundColor: colors[rating]}}>
    <span className={"me-1"}>{rating}</span>
    <FaStar />
  </span>
}
export default RenderRating
