import React from 'react';

function DevOnly({children, hide, object}) {
  if(process.env.NODE_ENV !== "development"){
    return null;
  }
  if(hide){
    return null;
  }
  if(object){
    return <>
        <pre style={{overflowX:'scroll', maxWidth:500}}>{JSON.stringify(object, null, 2)}</pre>
      {children}
    </>
  }
  return children || null;
}

export default DevOnly;
