import React from 'react';

Spinner.defaultProps = {
  size:16,
  className:'text-light'
}
function Spinner({size, className}) {
  return (
      <div className={`spinner-border ${className}`} style={{width:size, height:size}} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
  );
}

export default Spinner;
