import React, {useCallback, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {getHttpClient} from '../api';
import axios from 'axios';
import Spinner from './form/Spinner';
import {getFilename, isImage} from '../utils/func';
function FileUploaderComponent({accept, maxFiles, maxSize, value, onChange}) {
  const [loading, setLoading] = useState(false)
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    if(acceptedFiles.length > 0) {
      setLoading(true)
      doUpload(acceptedFiles[0]).then(({url}) => {
        console.log('got url', url)
        setLoading(false)
        onChange(url)
      })
    }
  }, [onChange])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept:accept,
    maxFiles,
    maxSize,
  })
  if(loading){
    return   <div style={{height:150}} className={`bg-light rounded border d-flex align-items-center justify-content-center`}>
      <Spinner  className={"text-primary"} size={48} />
    </div>
  }
  return (
      <div className={`bg-light rounded ${isDragActive ? 'border-primary' : 'border'}`}>
        <div {...getRootProps()} >
          <input {...getInputProps()} />
          {
            value ? <div className={'text-center'}>
                  {
                    isImage(value) ?
                        <img alt={"image"} src={value} style={{maxHeight: 150,  maxWidth:'100%'}}/> :
                        <div>{getFilename(value)}</div>
                  }
              </div> :
                <div style={{height:150,}} className={"d-flex align-items-center justify-content-center"}>
                  <div className={"text-center"}>Drag 'n' drop some files here, or click to select files</div>
                </div>
          }
        </div>
        {
          value ?
              <div className="text-center">
                <button type={"button"} onClick={() => onChange(null)}
                        className="btn btn-link text-danger">Delete</button>
              </div> : null
        }
      </div>
  );
}


async function doUpload(file){
  const {fields, url} = await getHttpClient().post('/admin/preSignedUrl',{
    name: file.name
  }).then(({data})=>{
    return data;
  })
  const form = new FormData()
  Object.keys(fields).forEach((k)=>{
    form.append(k, fields[k])
  })
  form.append('content-type',file.type)
  form.append('file', file)
  return axios.post(url, form).then(()=>{
    return {url: `https://media.uxbundle.com/${fields.key}`}
  })
}


export default FileUploaderComponent;
