import React from 'react'
import PropTypes from 'prop-types';
const Cmp = React.lazy(()=>import('./AsyncProductSelectorInternal'))
const AsyncProductSelector = (props)=><React.Suspense fallback={null}><Cmp {...props} /></React.Suspense>

AsyncProductSelector.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool
}

export default AsyncProductSelector
