import React from 'react';
import Skeleton from 'react-loading-skeleton';

const Cmp = React.lazy(()=>import('./ReactSelectInternal'))

function ReactSelect(props){
  return <React.Suspense fallback={<Skeleton style={{height:48}} />}>
    <Cmp {...props} />
  </React.Suspense>
}



export default ReactSelect
