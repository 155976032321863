import React, {useCallback} from 'react';
import Button from './form/Button';
import {Modal as BoostrapModal} from 'react-bootstrap'
function Modal({visible, size, children, confirmLoading, disabled, onClose, onCancel, title, okText, onOk, onSubmit, isSubmitDanger, cancelText}) {
  const onClick = useCallback((e)=>{
    if(e){
      e.preventDefault();
      e.stopPropagation();
    }
    if(onSubmit){
      onSubmit(e)
    }else if(onOk){
      onOk(e)
    } else if(onCancel){
      onCancel()
    }
  },[onSubmit, onOk, onCancel])
  return (
      <BoostrapModal size={size} show={visible} onHide={onClose || onCancel}>
        <BoostrapModal.Header closeButton>
          <BoostrapModal.Title>{title}</BoostrapModal.Title>
        </BoostrapModal.Header>
        <BoostrapModal.Body>
          {
            onSubmit ? <form onSubmit={onClick}>
                  {children}
            </form> : children
          }
        </BoostrapModal.Body>
        {
          onSubmit || onOk || onCancel ?
              <BoostrapModal.Footer>
                {
                  onCancel ?
                      <Button className={"btn"} title={cancelText || "Cancel"}
                              onClick={onCancel}/> : null
                }
                  <Button loading={confirmLoading}
                          disabled={confirmLoading || disabled}
                          className={`btn ${isSubmitDanger ?
                              `btn-danger` :
                              `btn-primary`}`} onClick={onClick}
                          title={okText || 'Submit'}/>
              </BoostrapModal.Footer> : null
        }
      </BoostrapModal>
  );
}

export default Modal;
