import React from 'react';
import InputGroup from './InputGroup';
import DefaultCurrencyRender from '../DefaultCurrencyRender';
import Input from './Input';

function CurrencyInput({value, onChange, min, suffix}) {

  return (
      <InputGroup prefix={<DefaultCurrencyRender />} suffix={suffix}>
        <Input type={"number"} value={value} onChange={onChange} min={min}/>
      </InputGroup>
  );
}

export default CurrencyInput;
