import React, {useCallback, useMemo} from 'react';
import {nanoid} from '../../func';

function Checkbox({id, label, value, onChange}) {
  const changed = useCallback((e)=>{
    onChange(e.target.checked)
  },[onChange])

  const i = useMemo(()=>id ? id : nanoid(),[id])
  return (
      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="" checked={value} onChange={changed}
               id={i}/>
          <label className="form-check-label" htmlFor={i}>
            {label}
          </label>
      </div>
  );
}

export default Checkbox;
