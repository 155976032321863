import create from 'zustand'
import {getHttpClient} from '../../../api';

const useEmailTemplates = create((set, get)=>{
  return {
    templates: null,
    locale: null,
    load:(locale)=>{
      return getHttpClient().get('/admin/sites/email/templates/'+locale)
          .then(({data})=>{
              set({templates: data.templates, locale})
          })
    },
    resetTemplate:(templateKey)=>{
      return getHttpClient().delete('/admin/sites/email/templates/'+get().locale+'/'+templateKey)
          .then(({data})=>{
            set({templates: data.templates})
          })
    },
    saveTemplate:(template)=>{
      return getHttpClient().put('/admin/sites/email/templates',{
        ...template,
        locale:get().locale,
      }).then(({data})=>{
        set({
          templates: data.templates
        })
      })
    }
  }
})
export default useEmailTemplates
