import React, {useEffect, useState} from 'react';
import useEmailTemplates from './store/useEmailTemplates';
import PageLoadingSpinner from '../../components/loaders/PageLoadingSpinner';
import useLocaleState from '../../components/hooks/useLocaleState';

export default function EmailTemplatesContainer({children}) {
  const [currentLocale] = useLocaleState()
  const [templates, load] = useEmailTemplates((state)=>[state.templates, state.load])
  const [loading, setLoading] = useState(false)
  useEffect(()=>{
    setLoading(true)
      load(currentLocale || 'en').then(()=>{
        setLoading(false)
      })
  },[load, currentLocale])
  if(loading){
    return <PageLoadingSpinner />
  }else if(templates) {
    return <>{children}</>
  }else return <p>Templates can't be loaded</p>;
}

