import React, {useEffect, useMemo, useState} from 'react';
import FormGroup from '../../components/form/FormGroup';
import AsyncCustomerSelector
  from '../../components/customer-selector/AsyncCustomerSelector';
import useObjectState from '../../components/hooks/useObjectState';
import LazyRangePicker from '../../components/datepickers/LazyRangePicker';
import Select from '../../components/form/Select';
import Pagination from '../../components/table/Pagination';
import {useSearchParams} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import EmptyCard from '../dashboard/components/EmptyCard';
import LoyaltyProgramSelector
  from '../../components/selectors/LoyaltyProgramSelector';
import RenderReward from './components/RenderReward';
import useLoyaltyRewards from './useLoyaltyRewards';

function useLimit(){
  const [searchParams] = useSearchParams()
  return useMemo(()=>{
    return Number(searchParams.get('limit') || 10) ||10;
  },[searchParams])
}
function usePage(){
  const [searchParams] = useSearchParams()
  return useMemo(()=>{
    return Number(searchParams.get('page') || 0) || 0;
  },[searchParams])
}

function RewardsIndex(props) {
  const [filter, setFilter] = useObjectState({})
  const [data,count, load] = useLoyaltyRewards((state)=>[state.data, state.count, state.load])
  const [error, setError] = useState(false)
  const limit = useLimit();
  const page = usePage();
  const [loading, setLoading] = useState(false)
  useEffect(()=>{
    setLoading(true)
    load(limit, Math.round(page * limit), filter).then(()=>{
      setLoading(false)
    }).catch((e)=>{
      setLoading(false)
      setError(true)
    })
  },[limit, page, filter, load])

  return (
      <>
        <h3>Loyalty Rewards</h3>
        <p className={"text-muted"}>List of rewards given to the customers for their completed tasks</p>
        <div className="my-2 border rounded bg-light p-2">
          <RewardsFilter filter={filter} setFilter={setFilter} />
        </div>
        {
          data.length > 0 ? <ul className={"list-group mb-2"}>
            {
              data.map((item)=>{
                return <RenderReward id={item._id} reward={item} />
              })
            }

          </ul> : <>
            {
              loading ? <Skeleton height={32} count={10} /> : <EmptyCard />
            }
          </>
        }
        {
          count > 0 &&
              <Pagination loading={loading} count={count} limit={limit}/>
        }
      </>
  );
}

const rewardTypes =[
  {
    label:'Points',
    value:'points'
  },
  {
    label:'Discount Codes',
    value:'coupon_code'
  },
]
const taskTypes =[
  {
    label:'Place an order',
    value:'place_an_order'
  },
  {
    label:'Refer a friend',
    value:'refer_a_friend'
  },
  {
    label:'Birthday reward',
    value:'birthday'
  },
  {
    label:'Redeem points',
    value:'redeem_points'
  },
  {
    label:'Post a review',
    value:'post_a_faq'
  },
  {
    label:'Wallet Adjustment',
    value:'wallet_adjustment'
  },
  {
    label:'Social Follow',
    value:'social_follow'
  },
  {
    label:'Social Share',
    value:'social_share'
  },
  {
    label:'Signup reward',
    value:'signup'
  },
  {
    label:'Expired Points',
    value:'expire_points'
  },
  {
    label:'Subscribed to Newsletter',
    value:'accepts_marketing'
  }
]
const statusTypes = [
  {
    label:'Approved',
    value:'approved'
  },
  {
    label:'Pending',
    value:'pending'
  },
  {
    label:'Rejected',
    value:'rejected'
  },
]

function RewardsFilter({filter, setFilter}){
  return <>
    <div className="row">
      <div className="col-md-4">
        <FormGroup label={"Filter by customer"}>
          <AsyncCustomerSelector value={filter.customerId} onChange={setFilter('customerId')} />
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup label={"Filter by dates"}>
          <LazyRangePicker value={filter.dates} onChange={setFilter('dates')} />
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup label={"Filter by program"}>
          <LoyaltyProgramSelector value={filter.programId} onChange={setFilter('programId')} />
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup label={"Filter by reward"}>
          <Select options={rewardTypes} hasEmpty value={filter.type} onChange={setFilter('type')} />
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup label={"Filter by Tasks"}>
          <Select options={taskTypes} hasEmpty value={filter.taskType} onChange={setFilter('taskType')} />
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup label={"Filter by status"}>
          <Select options={statusTypes} value={filter.status} hasEmpty onChange={setFilter('status')} />
        </FormGroup>
      </div>
    </div>
  </>
}




export default RewardsIndex;
