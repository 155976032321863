import React, {useCallback} from 'react';
import AutosizeTextArea from 'react-textarea-autosize'
function TextArea({value, rows, onChange}) {
  const changed = useCallback((e)=>{
    onChange(e.target.value)
  },[onChange])
  return (
      <AutosizeTextArea minRows={rows} className={"form-control"} value={value} onChange={changed} />
  );
}

export default TextArea;
