import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

const Cmp = React.lazy(()=>import('./CSVReaderInputComponent_internal'))

function CSVReaderInput(props){
  return <React.Suspense fallback={<Skeleton style={{height:48}} />}>
    <Cmp {...props} />
  </React.Suspense>
}


CSVReaderInput.propTypes ={
  onResult: PropTypes.func.isRequired,
  onLoadStart: PropTypes.func
}


export default CSVReaderInput
