import create from 'zustand';
import {getHttpClient} from '../../api';

const useWidgetStore = create((set, get)=>{
  return {
    widgets:null,
    widgetsByType:(widgetType)=> {
      return (get().widgets || []).filter(({type})=>type ===widgetType)
    },
    widgetByType:(widgetType)=> {
      return (get().widgets || []).find(({type})=>type ===widgetType)
    },
    getWidgetById:(id)=>{
      return (get().widgets || []).find(({_id})=>id == _id)
    },
    loadWidgets:()=> getHttpClient().get('/admin/widgets')
        .then(({data})=>data.widgets ? set({widgets: data.widgets}) : null),
    createWidget:({type, data})=>getHttpClient().post('/admin/widgets',{type, data})
        .then(({data})=>set({widgets: [data, ...get().widgets]})),
    updateWidget:(id, {data, isEnabled})=>getHttpClient().patch('/admin/widgets/'+id,{
      data, isEnabled
    }).then(({data})=>set({widgets: get().widgets.map((item)=>item._id === data._id ? data: item)})),
    deleteWidget:(id)=>getHttpClient().delete('/admin/widgets/'+id)
      .then(({data})=>set({widgets: get().widgets.filter((item)=>item._id != data.id)}))
  }
})

export default useWidgetStore
