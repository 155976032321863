import React, {useMemo} from 'react';
import {AiFillStar} from 'react-icons/ai';
import {FormattedNumber} from 'react-intl';

function RatingDistribution({ratings}) {
  const count = useMemo(()=>{
    return ratings ? Math.round(ratings.reduce((acc, cur)=>acc + cur,0)) : 0
  },[ratings])
  if(!ratings) return null;
  return (
      <>
        {
          ratings.map((item, index)=>{
            return <ReviewDistribution key={String(index)} count={item} rating={5-index} percentage={count > 0 ? Math.round((item/count) * 100) : 0} />
          })
        }
      </>
  );
}
const ratingColor = [
  '#e74c3c','#e67e22',
  '#f1c40f','#1abc9c',
  '#27ae60'
]

function ReviewDistribution({rating,percentage, count}){
  return <div>
    <div className="d-flex align-items-center">
      <div className="d-flex align-items-center">
        <span style={{width:'1rem'}}>{rating}</span>
        <AiFillStar />
      </div>
      <ProgressBar color={ratingColor[rating-1]} percentage={percentage} />
      <span style={{width:32}} className={"text-end"}>
          <FormattedNumber value={count} />
        </span>
    </div>
  </div>
}


function ProgressBar({percentage, color}){
  return <div className="mx-2 progress rounded-pill flex-grow-1" style={{height:14}}>
    <div className="progress-bar" role="progressbar" style={{backgroundColor:color, width:percentage+'%'}} aria-valuenow="0"
         aria-valuemin="0" aria-valuemax="100">
      {percentage}%
    </div>
  </div>
}

export default RatingDistribution;
