import {useCallback, useState} from 'react';

const useToggle = (v)=>{
  const [visible, setVisible] = useState(v)
  const toggle = useCallback(()=>{
      console.log('toggle called', visible)
      return setVisible((v)=>!v)
  },[visible])
  return [visible, toggle]
}
export default useToggle;
