import {FiArrowLeft} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';
BackButton.defaultProps = {
  size:16
}
export default function BackButton({to, size, hasTitle, replace}){
  const navigate = useNavigate()
  return <button className="btn p-0" onClick={()=>navigate(to || -1, {replace})}>
    <FiArrowLeft size={size} style={{strokeWidth: size > 20 ? 1 : undefined}} />
    {hasTitle ? <span className={"ms-2"}>Go back</span>  : null}
  </button>
}
