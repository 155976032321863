import React, {useMemo} from 'react';

function VideoPlayer({src}) {
  const s = useMemo(()=>{
    return src.replace(`https://video.bunnycdn.com/play/`,`https://iframe.mediadelivery.net/embed/`)+'?autoplay=true'
  },[src])
  if(!src) return null;
  return (
      <div style={{position: 'relative', paddingTop: "56.25%"}}>
        <iframe
            src={s}
            loading="lazy"
            style={{border:'none', position:"absolute", top:0, height:"100%", width:"100%"}}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowFullScreen="true"/>
      </div>
  );
}
export default VideoPlayer;
