export const shopify = {
  clientId: 'aae7880f3b12fdcd7680d8cff57caab4',
  scopes: 'write_orders,read_orders,read_customers,write_customers,read_script_tags,write_script_tags,read_products,write_products,read_price_rules,write_price_rules,read_themes,write_themes,write_content,read_content',
  redirect_url: process.env.NODE_ENV === "development" ? `http://localhost:4011/install/shopify/callback` : 'https://app.uxbundle.com/install/shopify/callback'
};
export const support_email = "support@goaffpro.com";

export const wixConfig = {
  appId:'1129148b-e312-4228-ad46-9e10c1f22229',
  redirect_url: 'https://app.goaffpro.com/wix/callback',
}

export const removeBilling = true
/*
export const woocommerce = {
  query:{
    user_id:'1',
    app_name:'GoAffPro',
    scope:'read_write',
    return_url:appURL+'/woocommerce/auth_callback', //the user is sent here
    callback_url:`${baseURL}/woocommerce/install` //the consumer key and secret are sent here
  },
  endpoint:'index.php/wc-auth/v1/authorize',
};
 */
