import React, {useCallback, useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import Modal from '../../components/Modal';
import useToggle from '../../components/hooks/useToggle';
import FormGroup from '../../components/form/FormGroup';
import CSVReaderInput from '../../components/csv/CSVReaderInput';
import Input from '../../components/form/Input';
import {getHttpClient} from '../../api';
import ListOfCustomers from './ListOfCustomers';
import CustomerDetails from './CustomerDetails';
import {Accordion} from 'react-bootstrap';
import Button from '../../components/form/Button';
import FileUploaderComponent from '../../components/FileUploaderComponent';
import {useSettingsStore} from '../../store/settingsStore';

function CustomersIndex(props) {
  return (
      <Routes>
        <Route path={":id/*"} element={<CustomerDetails />}/>
        <Route index element={<CustomersList/>}/>
        <Route path={"import"} element={<CustomersImport />} />
      </Routes>
  );
}

function CustomersImport(){
  return <>


  </>;
}

function PointsImport(){
  const [visible, toggle] = useToggle()
  const [csv, setCSV] = useState(null)
  const [customerNote, setCustomerNote] = useState('');
  const [adminNote, setAdminNote] = useState('');
  const [saving, setSaving] = useState(false)
  function onSave(e){
    if(e){
      e.preventDefault();
      e.stopPropagation();
    }
    setSaving(true)
    getHttpClient().post('/admin/data/import/points', {
      adminNote,
      customerNote,
      data: csv
    }).then(()=>{
      setSaving(false)
      toggle()
    })
  }
  const onData = useCallback((data)=>{
      setCSV(data.map((item)=>{
        return {
          name: item.name,
          email: item.email,
          points: Number(item.points)
        }
      }))
  },[])
  return <>
    <button className="btn btn-link" onClick={toggle}>Import points</button>
    <Modal onSubmit={onSave} disabled={!csv || csv.length === 0} confirmLoading={saving} visible={visible} title={"Adjust points"} onClose={toggle}>
      <a title={"Sample CSV"} rel={"noopener noreferrer nofollow"} href={"/sample_points.csv"} target={"_blank"} download>Download sample CSV</a>
      <FormGroup label={"Upload file"}>
        <CSVReaderInput accept={".csv"} onResult={onData} />
      </FormGroup>
      {
        csv ? <>
            <div className="card-group">
              <div className="card">
                <div className="card-body">
                  <div className="fs-4">
                    {csv.length}
                  </div>
                  Total customers
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="fs-4">
                    {Math.round(csv.reduce((acc, cur) => acc + cur.points, 0))}
                  </div>
                  Total points
                </div>
              </div>
            </div>
            <FormGroup label={"Customer note (optional)"}>
              <Input value={customerNote} onChange={setCustomerNote}/>
            </FormGroup>
              <FormGroup label={"Admin note (optional)"}>
                <Input value={adminNote} onChange={setAdminNote}/>
              </FormGroup>
             </>
          : null
      }
    </Modal>
  </>
}

function CustomersList(){
  const [forceReload, setForceReload] = useState(0)
  return <>
    <div className="row">
      <div className="col">
        <h2>Customers</h2>
        <p className="text-muted">Manage customers points and reviews</p>
      </div>
      <div className="col-auto">
        <div className="d-flex align-items-center">
          <ImportCustomersModal onChanged={()=>setForceReload((s)=>s+1)} />
          <PointsImport />
        </div>
      </div>
    </div>
    <ListOfCustomers forceReload={forceReload} />
  </>
}

function ImportCustomersModal({onChanged}){
  const [visible, toggle] = useToggle(false)

  const [importing, setImporting] = useState(false)
  const importCustomers = useCallback(()=>{
      setImporting(true)
      getHttpClient().post('/admin/data/import/store_customers')
          .then((row)=>{
            setImporting(false)
            toggle()
            onChanged()
          })
  },[onChanged, toggle])
  return <>
    <Modal visible={visible} title={"Import customers"} onClose={toggle}>
      <Accordion defaultActiveKey={"0"}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Import existing store customers</Accordion.Header>
          <Accordion.Body>
            <p>You can import your already existing store customers into the system using the button below</p>
            <p>Note: This will allow you to bulk import customer's points and reviews</p>
             <Button loading={importing} onClick={importCustomers} className="btn btn-primary">Import Store Customers</Button>


          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Let our team handle the import <span className={"ms-2 badge bg-primary"}>Recommended</span></Accordion.Header>
          <Accordion.Body>
            <p>Upload file from your current loyalty system here and we will format and import it from our end.</p>
            <p>Since this import is done by a qualified computer engineer, it can take up-to 24 hours (usually 1-2 hours). We will notify you when the import is completed</p>
            <SetupServiceImport />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Import customers with loyalty points</Accordion.Header>
          <Accordion.Body>
            <p>You can also import customers with existing points. If any customers already exist in the app, the points will be added to their account</p>
            <div className={"mb-4 text-center"}>Download <a href={"/sample_customer_with_points.csv"}
                                                            target={"_blank"}
                                                            download
                                                            rel={"noopener nofollow noreferrer"}
                                                            title={"Download sample CSV file"}>sample CSV</a> file</div>
          <ImportCustomersWithPoints onChange={()=>{
            onChanged()
            toggle()
          }} />

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

    </Modal>
    <button className={"btn btn-link"} onClick={toggle}>Import customers</button>
  </>
}

function ImportCustomersWithPoints({onChange}){
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const submit = useCallback(()=>{
    setLoading(true)
    getHttpClient().post('/admin/data/import/customers',{
      data,
    }).then(()=>{
      setLoading(false)
      onChange()
    })
  },[data, onChange])

  const onResult = useCallback((d)=>{
    setData(d.map((i)=>{
      if(i['Referral Code'].length > 40) {
        console.log(i)
      }
      return {
        firstName: i['First Name'],
        lastName:i['Last Name'],
        name: i['Name'],
        emailAddress:i['Email Address'],
        points:i['Points'],
        referralCode:i['Referral Code']
      }
    }))
  },[])

  return   <>
    <FormGroup label={"Upload file"}>
      <CSVReaderInput onResult={onResult} />
    </FormGroup>
    {
      data && data.length > 0 ? <>
          <div className={"card mb-2"}>
            <div className="card-body">
              <h2>{data.length}</h2>
              <p className={"text-muted"}>Customers</p>
            </div>
          </div>
          <Button onClick={submit} loading={loading} className={"btn btn-primary"}>Import</Button>
      </> : null
    }
  </>
}

function SetupServiceImport(){
  const [customerImportFile, pendingRequests, updateSettings] = useSettingsStore((state)=>
      [state.settings.customerImportFile, state.settings.setupRequestsPending, state.updateSettings]
  )

  function onUpload(url){
    updateSettings({
      setupRequestsPending: [...(pendingRequests || []), 'DataImportLoyaltyProgram'],
      customerImportFile:url,
    }).then(r => {

    })
  }

  return <>
    <FormGroup label={"Upload file"}>
    <FileUploaderComponent value={customerImportFile} onChange={onUpload} />
  </FormGroup>
    {
      customerImportFile ? <p className={"alert alert-success"}>
        Thank you! Your data import request is being processed. We will update you once the import is completed
      </p> : null
    }
  </>
}


export default CustomersIndex;
