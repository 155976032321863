import RenderCustomer from '../../reviews/components/RenderCustomer';
import {taskIcons} from '../../loyalty/components/program_data';
import {
  RenderDiscountValue,
  RenderTaskType,
} from '../../../components/RenderDiscountValue';
import {FiGift} from 'react-icons/fi';
import React, {useCallback, useState} from 'react';
import {FormattedNumber} from 'react-intl';
import RenderDate from '../../../components/RenderDate';
import useLoyaltyRewards from '../useLoyaltyRewards';
import Button from '../../../components/form/Button';

function RenderReward({reward, hideCustomer}){
  return <li className={"list-group-item"}>
    <div className="d-flex align-items-center justify-content-between">
      {!hideCustomer ?  <RenderCustomer customer={reward.customer} /> : null }
      <RenderDates reward={reward} />
    </div>
    <div className={"d-flex mt-1 align-items-center justify-content-between"}>
      <div>
        <div className={"d-flex align-items-center"}>
          <RenderTaskType item={reward} />
        </div>
        <div className={"d-flex align-items-center"}>
          <FiGift className={"me-2 text-secondary"} /> <RenderGift item={reward} />
        </div>
      </div>
      <div className={"text-end"}>
        <StatusButtons _id={reward._id} status={reward.status} />
      </div>
    </div>
  </li>
}

function RenderDates({reward}){
  return    <div className={"text-end d-inline-flex flex-column text-muted"}>
    <small>
      <RenderDate value={reward.createdAt}/>
    </small>
  </div>
}


function RenderGift({item}){
  if(item.type === "coupon_code" && item.discountConfig){
    return <><RenderDiscountValue
        freeProduct={item.discountConfig.freeProduct}
        discountValue={item.discountConfig.discountValue}
                                  discountType={item.discountConfig.discountType}/>
      <code title={"Coupon code"} className={"ms-2"}>({item.coupon_code})</code>
    </>
  }else if(item.type === "points"){
    return <><FormattedNumber value={item.amount} /> points</>
  }else{
    return null;
  }
}


function StatusButtons({_id, status}){
  const updateStatus = useLoyaltyRewards((state)=>state.updateStatus)
  const deleteReward = useLoyaltyRewards((state)=>state.deleteReward)
  const [deleting, setDeleting] = useState(false)
  const onClick = useCallback((newStatus)=>{
    return ()=>updateStatus(_id, newStatus)
  },[_id, updateStatus])
  const onDelete = useCallback(()=>{
    if(window.confirm('Are you sure you want to delete this reward entry')){
      setDeleting(true)
      deleteReward(_id).then(()=>{
        setDeleting(false)
        console.log('deleted')
      })
    }
  },[_id, deleteReward])
  if(status === "pending"){
    return  <>
      <button onClick={onClick('rejected')} className="btn btn-link text-danger px-0 me-2">Reject</button>
      <button onClick={onClick('approved')}  className="btn btn-link text-success px-0">Approve</button>
    </>
  }else if(status === "approved"){
    return  <>
      <button onClick={onClick('rejected')} className="btn btn-link text-danger">Reject</button>
    </>
  }else if(status === "rejected"){
    return  <>
      <button onClick={onClick('approved')} className="btn btn-link text-success">Approve</button>
      <Button loading={deleting} onClick={onDelete} className="btn btn-link text-danger">Delete</Button>
    </>
  }
  return null;
}

export default RenderReward
