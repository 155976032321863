import create from 'zustand';
import {getHttpClient} from '../../api';

const useAuth = create((set, get)=>{
  return {
  userId: null,
  siteId: null,
  sites:null,
  initialized:false,
  profile: null,
  error:false,
  init:()=>{
    console.log(localStorage.access_token)
    if(!localStorage.getItem('access_token')){
      set({initialized:true})
    }else{
      getHttpClient().get('/auth/sites').then(({data})=>{
        const {userId, siteId, sites, profile,} = data;
        set({
          userId,
          siteId,
          profile,
          sites,
          initialized:true
        })
      }).catch(()=>{
//        localStorage.removeItem('access_token')
        set({initialized:true, error:true})
      })

    }
  },
  setCurrentSite:(index)=>{
    const {_id, access_token} = get().sites[index];
    localStorage.setItem('access_token', access_token)
    set({
      siteId: _id,
    })
  }
}})

export default useAuth;
