import React, {useCallback, useMemo} from 'react';
import {
  useNavigate,
  useLocation,
  Link,
  useSearchParams,
} from 'react-router-dom';
import Spinner from '../form/Spinner';

function appendToQuery(location, queryObject){
  const u = new URLSearchParams(location.search);
  Object.keys(queryObject).forEach((k)=>{
    u.set(k, queryObject[k])
  })
  return location.pathname + "?"+u;
}

export default function Pagination({currentPage, loading, count, limit, onLimitChange, onNext, onPrevious}){
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()
  const page = useMemo(()=>{
    if(currentPage !== undefined) return currentPage;
    else return Number(searchParams.get('page') || 0);
  },[searchParams, currentPage])

  const next = appendToQuery(location,{page:page + 1})
  const previous = appendToQuery(location,{page:page - 1})
  const limitChanged = useCallback((e)=>{
    if(onLimitChange){
      return onLimitChange(e.target.value)
    }else{
      return ()=> navigate(appendToQuery(location,{page:0,limit:e.target.value}), {replace:true})
    }
  },[location, navigate, onLimitChange])

  const maxPages = useMemo(()=>{
    return Math.ceil(count/limit);
  },[count, limit])

  const canPrevious = page > 0
  const canNext = maxPages-1 > page;
  return (
      <div className="d-flex justify-content-between align-items-center">
        <select className="form-control" value={limit} onChange={limitChanged} style={{maxWidth:150}}>
          <option value="3">3 per page</option>
          <option value="10">10 per page</option>
          <option value="25">25 per page</option>
          <option value="50">50 per page</option>
          <option value="100">100 per page</option>
        </select>
        <span>{`Page ${page+1} of ${maxPages}`}</span>
        <nav aria-label="Page navigation" className={'d-flex align-items-center'}>
          {
            loading &&
                <Spinner className={"text-primary me-2"} size={20}/>
          }
          <ul className="pagination mb-0">
            <li className={`page-item ${!canPrevious ? 'disabled' : ''}`}>
              {
                onPrevious ?
                  <button onClick={onPrevious} disabled={!canPrevious} className="page-link">Previous</button>:
                  <Link to={previous} disabled={!canPrevious} className="page-link">Previous</Link>
              }
            </li>
            <li className={`page-item ${!canNext ? 'disabled' : ''}`}>
              {
                onNext ?
              <button onClick={onNext} className="page-link">Next</button>
                    :
                <Link to={next} disabled={!canNext} className="page-link">Next</Link>
              }
            </li>
          </ul>
        </nav>
      </div>
  )
}
