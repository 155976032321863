import React from 'react'
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton'
const Cmp = React.lazy(()=>import('./AsyncCustomerSelectorInternal'))
const AsyncCustomerSelector = (props)=><React.Suspense fallback={<Skeleton height={36}/>}><Cmp {...props} /></React.Suspense>

AsyncCustomerSelector.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool
}

export default AsyncCustomerSelector
