import React from 'react';
import Select from '../components/form/Select';
import Input from '../components/form/Input';



function CsvFieldsMapper({values, onChange, supportedHeadings, csvHeadings}) {
  return (
      <>
        {
          supportedHeadings.map(({label, isTextInput, value})=>{
            return <MapperItem csvHeadings={csvHeadings}
                               key={String(label)}
                               value={values[value]}
                               textInput={isTextInput}
                               onChange={onChange(value)}
                               heading={label} />
          })
        }
      </>
  );
}

function MapperItem({csvHeadings, heading, value, onChange, textInput}){
  return  <div className="row mb-2 align-items-center">
    <div className="col-6">
      {heading}
    </div>
    <div className="col-6">
      {
        textInput ? <Input value={value} onChange={onChange}/> :
            <Select hasEmpty options={csvHeadings} value={value}
                    onChange={onChange}/>
      }
    </div>
  </div>
}

export default CsvFieldsMapper;
