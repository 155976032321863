import React, {useCallback, useState} from 'react';
import FormGroup from '../../components/form/FormGroup';
import InputGroup from '../../components/form/InputGroup';
import useObjectState from '../../components/hooks/useObjectState';
import Input from '../../components/form/Input';
import {getHttpClient} from '../../api';
import * as localStorage from '../../utils/storage';
import Button from '../../components/form/Button';

function CreateShopifyPrivateApp(props) {
  const [data, setData] = useObjectState({})
  const [loading, setLoading] = useState(false)
  const install = useCallback(()=>{
    setLoading(true)
    getHttpClient().post('/shops/shopify/private-app-install',{
      shop: `${data.myshopify}.myshopify.com`,
      shopifyAccessToken: data.shopifyAccessToken
    }).then(({data})=>{
      setLoading(false)
      localStorage.setItem("access_token", data.access_token);
      window.location = "/";
    })
  },[data])
  return (
      <div className="bg-light min-vh-100">
        <div className="container" style={{paddingTop:80}}>
          <div className="row">
            <div className="col-md-4"/>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <FormGroup label={"myshopify URL"}>
                    <InputGroup suffix={"myshopify.com"}>
                      <Input value={data.myshopify} onChange={setData('myshopify')} />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup label={"Admin API Access token"}>
                    <Input value={data.shopifyAccessToken} onChange={setData('shopifyAccessToken')} />
                  </FormGroup>
                  <Button onClick={install} loading={loading} className="btn btn-primary">Submit</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default CreateShopifyPrivateApp;
