import React from 'react';
import {useSettingsStore} from '../store/settingsStore';
import currencies from '../currencies';

function DefaultCurrencyRender(props) {
  const default_currency = useSettingsStore((state)=>state.settings.defaultCurrency || 'USD')
  return (
      <span>
        {currencies[default_currency]?.symbol_native || ''}
      </span>
  );
}

export default DefaultCurrencyRender;
