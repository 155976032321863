import React, {useCallback, useState} from 'react';
import useToggle from '../../components/hooks/useToggle';
import Modal from '../../components/Modal';
import FormGroup from '../../components/form/FormGroup';
import useObjectState from '../../components/hooks/useObjectState';
import Input from '../../components/form/Input';
import TextArea from '../../components/form/TextArea';
import AsyncCustomerSelector
  from '../../components/customer-selector/AsyncCustomerSelector';
import AsyncProductSelector
  from '../../components/product-selector/AsyncProductSelector';
import FileUploaderComponent from '../../components/FileUploaderComponent';
import {randomString} from '../../utils/func';
import {FiTrash} from 'react-icons/fi';
import Select from '../../components/form/Select';
import {getHttpClient} from '../../api';

const ratingOptions = [5,4,3,2,1].map((i)=>{
  return {
    label:`${i} stars`,
    value:i
  }
})
function ProductReviewsCreator(props) {
  const [visible, toggle] = useToggle(false)
  const [media, setMedia] = useState([
    {
      id:randomString(),
      value:null
    }
  ])
  const [data, setData] = useObjectState({
    title:'',
    description:'',
    customer:null,
    product: null,
    attachments: null,
    created_at: null,
    rating:5,
  })
  const addMedia = useCallback(()=>{
    setMedia((media)=>[...(media || {}), {id: randomString()}])
  },[])
  const removeMedia = useCallback((id)=>{
    return ()=>{
      setMedia((media)=>media.filter((i)=>i.id !== id))
    }
  },[])
  const [loading, setLoading] = useState(false)
  const onSubmit = useCallback((e)=>{
      setLoading(true)
      const {rating, title, description, customer, product, date} = data;
      const customerId = customer.value;
      const productId = product.value;
      const createdAt = new Date(date)
      getHttpClient().post('/admin/reviews',{
        rating, title, description, customerId, productId, status: 'approved',createdAt,
        attachments: media.filter(({value})=>value).map(({value})=>{
          return {
            type:'image',
            src: value,
          }
        })
      }).then(()=>{
        setLoading(false)
        toggle()
        if(props.onChange) props.onChange()
      })
  },[data, media, props, toggle])
  const onFileUpload = useCallback((id)=>{
    return (value)=>{
      setMedia((m)=>m.map((i)=>i.id === id ? {...i, value} : i))
    }
  },[])
  return (
      <>
        <button className="btn btn-link" onClick={toggle}>+new review</button>
        <Modal onSubmit={onSubmit}
               confirmLoading={loading}
               onCancel={toggle} title={"Create new review"} visible={visible} onClose={toggle}>
          <FormGroup label={"Rating"}>
            <Select options={ratingOptions} value={data.rating} onChange={setData('rating')} />
          </FormGroup>
            <FormGroup label={"Title"}>
                <Input value={data.title} onChange={setData('title')} />
            </FormGroup>
          <FormGroup label={"Details"} required>
            <TextArea value={data.description} onChange={setData('description')} />
          </FormGroup>
          <FormGroup label={"Customer"} required>
            <AsyncCustomerSelector value={data.customer} onChange={setData('customer')} />
          </FormGroup>
          <FormGroup label={"Product"} required>
            <AsyncProductSelector value={data.product} onChange={setData('product')} />
          </FormGroup>
          <FormGroup label={"Review Date"} required>
            <Input type={"date"} onChange={setData('date')} />
          </FormGroup>
          <FormGroup label={"Media assets"}>
            {
                media ? media.map((i)=>{
                return <>
                <FileUploaderComponent value={i.value} onChange={onFileUpload(i.id)} id={i.id} />
                  <button className={'btn btn-link text-danger'} onClick={removeMedia(i.id)}><FiTrash /></button>
                </>
              }) : null
            }
            <button onClick={addMedia} className="text-center w-100 btn btn-link">Add another media asset</button>
          </FormGroup>
        </Modal>
      </>
  );
}

export default ProductReviewsCreator;
