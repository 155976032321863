import {Link} from 'react-router-dom';
import React from 'react';
import BackButton from '../../components/BackButton';
import SettingsSection from '../../components/SettingsSection';
import {AiOutlineFormatPainter} from 'react-icons/ai';
import AvailableLanguageSelector
  from '../../components/locale/AvailableLanguagesSelector';
import useLocaleState from '../../components/hooks/useLocaleState';
import {useSettingsStore} from '../../store/settingsStore';
import FormGroup from '../../components/form/FormGroup';
import {langmap} from '../../supportedLanguages';



function EmailTemplatesIndex(){
  const [currentLocale, setCurrentLocale] = useLocaleState()
  const supportedLanguages = useSettingsStore((state)=>state.settings.supportedLanguages)
  return <>
    <BackButton />
    <div className="row">
      <div className="col-md-6">
        <h2>Email Notifications <span style={{fontSize:14}} className="badge bg-info">{langmap[currentLocale]}</span></h2>
        <p className={"text-muted"}>Emails sent to your customers to keep them engaged</p>
      </div>
      <div className="col-md-6">
        {
          supportedLanguages.length > 1 ? <FormGroup label={"Choose language"}>
            <AvailableLanguageSelector value={currentLocale} onChange={setCurrentLocale} />
          </FormGroup> : null
        }
      </div>
    </div>
    <SettingsSection title={"Standard Template"} subtitle={"Set default styles for email header, Call-To-Action button, and Footer"}>
      <Link to={"/notifications/edit/standard"}>
        <AiOutlineFormatPainter className={"me-2"} />Customize Default styles</Link>
    </SettingsSection>
    <SettingsSection title={"Loyalty program templates"} subtitle={`Language : ${langmap[currentLocale]}`}>
      <ul className="list-group">
        <Link to={"/notifications/edit/loyaltyRewardApproved"} className="list-group-item py-3">Task Reward Approved notification</Link>
        <Link to={"/notifications/edit/loyaltyRewardRejected"} className="list-group-item py-3">Task Reward Rejected notification</Link>
        <Link to={"/notifications/edit/loyaltyBirthdayReward"} className="list-group-item py-3">Birthday Reward</Link>
        <Link to={"/notifications/edit/loyaltyPlaceAnOrder"} className="list-group-item py-3">Personal Shopping Reward</Link>
        <Link to={"/notifications/edit/loyaltyReferredCustomer"} className="list-group-item py-3">Referral Reward</Link>
        <Link to={"/notifications/edit/loyaltyPointsExpiring"} className="list-group-item py-3">Points about to expire notification</Link>
        <Link to={"/notifications/edit/loyaltyVoucherExpiring"} className="list-group-item py-3">Voucher about to expire notification</Link>
      </ul>
    </SettingsSection>
    <SettingsSection title={"Product reviews templates"} subtitle={`Language : ${langmap[currentLocale]}`}>
      <ul className="list-group">
        <Link to={"/notifications/edit/reviewAfterOrder"} className="list-group-item py-3">Ask for review notification</Link>
        <Link to={"/notifications/edit/reviewReplied"} className="list-group-item py-3">Review replied notification</Link>
        <Link to={"/notifications/edit/reviewPublished"} className="list-group-item py-3">Review published notification</Link>
      </ul>
    </SettingsSection>
    <SettingsSection title={"FAQ Templates"} subtitle={`Language : ${langmap[currentLocale]}`}>
      <ul className="list-group">
        <Link to={"/notifications/edit/faqAnswered"} className="list-group-item py-3">FAQ Answered notification</Link>
      </ul>
    </SettingsSection>
    <div className="d-none">
      <SettingsSection title={"Custom Templates"}>
        <ul className="list-group">

        </ul>
      </SettingsSection>
    </div>
  </>
}


export default EmailTemplatesIndex;
