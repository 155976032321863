import React, {useEffect, useState} from 'react';
import EmptyCard from './EmptyCard';
import Skeleton from 'react-loading-skeleton';
import RenderReward from '../../rewards/components/RenderReward';
import useLoyaltyRewards from '../../rewards/useLoyaltyRewards';

function RecentRewards(props) {
  const data = useLoyaltyRewards((state)=>state.data)
  const load = useLoyaltyRewards((state)=>state.load)
  const [loading, setLoading] = useState(false)
  useEffect(()=>{
    setLoading(true)
    load(5, 0).then(()=>{
      setLoading(false)
    })
  },[load])
  return (
      <div>
        {
          data.length > 0 ? <ul className="list-group list-group-flush">
            {
              data.map((item) => {
                return <RenderReward key={String(item._id)} id={item._id} reward={item}/>
              })
            }
          </ul> : <>
            {
              loading ? <Skeleton count={5} height={32} /> : <EmptyCard />
            }
          </>
        }

      </div>
  );
}


export default RecentRewards;
