import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getHttpClient} from '../../api';
import Skeleton from 'react-loading-skeleton';
import FormGroup from '../../components/form/FormGroup';
import Input from '../../components/form/Input';
import Button from '../../components/form/Button';
import Form from '../../components/form/Form';

function ManagerAccountSetup(props) {
  const [searchParams] = useSearchParams()
  const [userData, setUserData] = useState()
  const [initLoading, setInitLoading] = useState(false)
  const token  = useMemo(()=>{
    return searchParams.get('t');
  },[searchParams])
  useEffect(()=>{
    if(token) {
      setInitLoading(true)
      getHttpClient().get('/auth/setup_manager_account', {
        params: {
          emailToken: token,
        }
      }).then(({data}) => {
        setInitLoading(false)
        setUserData(data)
      })
    }
  },[token])
  const [name, setName] = useState(null)
  const [password, setPassword] = useState(null)
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const onSubmit = ()=>{
    setAccepting(true)
    return getHttpClient().post('/auth/setup_manager_account', {
      emailToken:token,
      name, password,
    }).then(({data})=>{
      if(data.access_token){
        localStorage.setItem('access_token', data.access_token)
        navigate("/?siteId="+userData.store._id, {replace: true})
      }else{
        setError(data.error || 'Unexpected error')
      }
    })
  }
  const [rejected, setRejected] = useState(false)
  function rejectInvite(){
    setRejecting(true)
    getHttpClient().post('/auth/setup_manager_account',{
      rejectInvite:true,
      emailToken:token,
    }).then(()=>{
      setRejected(true)
      setRejecting(false)
    })
  }
  const [accepting, setAccepting] = useState(false)
  const [rejecting, setRejecting] = useState(false)
  if(rejected){
    return   <div className="bg-light min-vh-100">
      <div className="container" style={{paddingTop:80}}>
        <h5>Thank you!</h5>
        <p>Your manager invitation is now declined. No further action is required from your end</p>
      </div>
    </div>
  }
  return (
      <div className="bg-light min-vh-100">
        <div className="container" style={{paddingTop:80}}>
          <div className="row">
            <div className="col-md-4"/>
            <div className="col-md-4">
              {
                initLoading ? <Skeleton height={300} /> : <>
                  {
                    userData ? <>
                  {
                    userData.store.logo ?
                        <div className="text-center">
                          <img alt={userData.store.name} src={ userData.store.logo} style={{width:200}} />
                        </div>
                        : null
                  }
                  <div className="card">
                    <div className="card-body">
                      <h1>Hi!</h1>
                      <p>
                        {userData.store.name} has invited you to manage their store app.
                      </p>
                      <Form onSubmit={onSubmit}>
                        {
                          error ? <p className="alert alert-danger">{error}</p> : null
                        }
                        {
                          !userData.hasPassword ? <>
                            <FormGroup label={"Your name"}>
                              <Input type="text" value={name}
                                     onChange={setName}/>
                            </FormGroup>
                            <FormGroup label={"Setup password"}>
                              <Input type="password" value={password}
                                     onChange={setPassword}/>
                            </FormGroup>
                          </> : null
                        }
                      <div className="d-flex justify-content-between">
                        <Button type={"submit"} loading={accepting} className="btn btn-primary">Accept</Button>
                        <Button onClick={rejectInvite} type={"button"} loading={rejecting} className="btn btn-link text-danger">Decline</Button>
                      </div>
                      </Form>
                    </div>
                  </div>
                </> : null
              }
                </>
              }
            </div>
          </div>
        </div>
      </div>
  );
}

export default ManagerAccountSetup;
