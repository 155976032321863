import React from 'react';
import {Link} from 'react-router-dom';

function RenderCustomer({customer}) {
  if(!customer) return null;
  return (
      <>
        <Link to={"/customers/"+customer._id} className="link-secondary" title={customer.emailAddress}>{customer.name || customer.emailAddress}</Link>
      </>
  );
}

export default RenderCustomer;
