import React from 'react';
import {Link} from 'react-router-dom';
import {AiFillStar} from 'react-icons/ai';
import {useSettingsStore} from '../../store/settingsStore';

const siteIdReducer = (state)=>state.settings._id
const platformReducer = (state)=>state.settings.platform
function NeedHelpSidebar() {
  const platform = useSettingsStore(platformReducer)
  const siteId = useSettingsStore(siteIdReducer)

  const openReviewWindow  = (e)=>{
    if(e){
      e.preventDefault();
      e.stopPropagation();
    }
    window.open(e.target.href, '_blank','width=700,height=400')
  }

  return (
      <div className={"px-3 my-4"}>
        <h4 className={""}>Need Help</h4>
        <p className={"text-muted"}>Get in touch</p>
        <Link to={"/contact"} className={"btn btn-outline-secondary"}>Contact</Link>
        {
            getReviewLink(platform) && <>
              <p className={"mt-4 text-muted"}>Like the app ? Review us on the App Store <br/> <span>
                  <AiFillStar style={{color: '#f39c12', fontSize: '1.2rem'}}/>
                  <AiFillStar style={{color: '#f39c12', fontSize: '1.2rem'}}/>
                  <AiFillStar style={{color: '#f39c12', fontSize: '1.2rem'}}/>
                  <AiFillStar style={{color: '#f39c12', fontSize: '1.2rem'}}/>
                  <AiFillStar style={{color: '#f39c12', fontSize: '1.2rem'}}/>
             </span>
              </p>
              <a rel={"noopener noreferrer"} onClick={openReviewWindow} href={getReviewLink(platform)}
                 target={"_blank"} className={"btn btn-outline-primary"}>App Store</a>
            </>
        }
        <div className="text-muted mt-4" style={{fontSize:'0.6rem'}}>
          <p>ID: {siteId}<br/>Platform: {platform}</p>
        </div>
      </div>
  );
}

export function getReviewLink(integration = "shopify"){
  switch (integration) {
    case "shopify":
      return "https://apps.shopify.com/uxbundle#modal-show=ReviewListingModal&utm_campaign=installed&utm_content=contextual&utm_medium=shopify&utm_source=admin"
    case "wordpress":
      return 'https://wordpress.org/plugins/goaffpro'
    case "wix":
      return "https://www.wix.com/app-market/add-review/goaffpro-affiliate-marketing"
    case "bigcommerce":
      return "https://www.bigcommerce.com/apps/goaffpro/"
    default:
      return null;
  }
}

export default NeedHelpSidebar;
