import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useToggle from '../../components/hooks/useToggle';
import uniq from 'lodash.uniq';
import useObjectState from '../../components/hooks/useObjectState';
import {
  reviewsDefaultMap,
  reviewsImportSupportedHeadings,
} from './components/import-functions';
import Modal from '../../components/Modal';
import {Accordion} from 'react-bootstrap';
import FormGroup from '../../components/form/FormGroup';
import FileUploaderComponent from '../../components/FileUploaderComponent';
import CSVReaderInput from '../../components/csv/CSVReaderInput';
import CSVFieldsMapper from '../CSVFieldsMapper';
import {useSettingsStore} from '../../store/settingsStore';
import Button from '../../components/form/Button';
import DevOnly from '../../components/DevOnly';
import {getHttpClient} from '../../api';

export default function ProductReviewsImporter({onChange}){
  const [data, setData] = useState(null)
  const [visible, toggle] = useToggle(false)

  const uniqueProductsCount = useMemo(()=>{
    return data ? uniq(data.map((i)=>i.productId || i.SKU || i['Product ID (optional)'])).length : null
  },[data])
  useEffect(()=>{

  },[])

  const csvHeadings = useMemo(()=>{
    return data ? Object.keys(data[0]).map((item)=>{
      return {
        label: item,
        value: item,
      }
    }) : []
  },[data])
  const [csvMap, setCSVMap, replace] = useObjectState()
  useEffect(()=>{
    return data ? replace(reviewsDefaultMap(data)) : {}
  },[data, replace])
  const [supportUrl, setSupportUrl] = useState(null)
  const [uploading, setUploading] = useState(false)
  function doImport(){
    //prepare data as per mapping
    const d = data.map((i)=>{
      const o = {}
      Object.keys(csvMap).forEach((key)=>{
        o[key] = i[csvMap[key]]
      })
      return o;
    })
    setUploading(true)
    getHttpClient().post('/admin/data/import/reviews',{
      data: d
    }).then(({data})=>{
      setUploading(false)
      setData(null)
      toggle()
      onChange()
    }).catch(()=>{
      setUploading(false)
    })
  }
  return <>
    <Modal visible={visible}
           disabled={!data || data.length === 0}
           onClose={toggle}
           title={"Import product reviews"}>
      <Accordion defaultActiveKey={"0"}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Let our team handle import<span className="ms-2 badge bg-primary">Recommended</span></Accordion.Header>
          <Accordion.Body>
            <p>Upload file from your current review system here and we will format it and import it correctly from our end.</p>
            <p>Since this import is done by a qualified computer engineer, it can take up-to 24 hours (usually 1-2 hours). We will notify you when the import is completed</p>
           <SetupServiceImport/>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Import reviews yourself</Accordion.Header>
          <Accordion.Body>
            <div className={"mb-4 text-center"}>Download <a href={"/sample_reviews.csv"}
                                                            target={"_blank"}
                                                            download
                                                            rel={"noopener nofollow noreferrer"}
                                                            title={"Download sample CSV file"}>sample CSV</a> file</div>
            <FormGroup label={"Upload file"} info={"You don't have to format it, just export from your current and upload it here. We will do the mapping in the next step"}>
              <CSVReaderInput onResult={setData} />
            </FormGroup>
            {
              data ?<>
                    <CSVFieldsMapper
                        values={csvMap}
                        onChange={setCSVMap}
                        supportedHeadings={reviewsImportSupportedHeadings}
                        csvHeadings={csvHeadings} />
                    <div className="card-group">
                      <div className="card">
                        <div className="card-body">
                          <div className="fs-5">
                            {data.length}
                          </div>
                          <span>Total reviews</span>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <div className="fs-5">
                            {uniqueProductsCount}
                          </div>
                          <span>Total products</span>
                        </div>
                      </div>
                    </div>
                  </>
                  : null
            }
            {
              data ? <>
                <DevOnly object={{csvMap, data}} />
                  <Button loading={uploading} onClick={doImport} className="btn btn-primary w-100">Submit</Button>
                  </>
                  : null
            }

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

    </Modal>
    <button className="btn btn-link" onClick={toggle}>Import product reviews</button>
  </>
}


function SetupServiceImport(){
  const [reviewsImportFile, pendingRequests, updateSettings] = useSettingsStore((state)=>
      [state.settings.reviewsImportFile, state.settings.setupRequestsPending, state.updateSettings]
  )

  function onUpload(url){
    updateSettings({
      setupRequestsPending: [...(pendingRequests || []), 'DataImportProductReviews'],
      reviewsImportFile: url,
    }).then(r => {

    })
  }

  return <>
    <FormGroup label={"Upload file"}>
      <FileUploaderComponent value={reviewsImportFile} onChange={onUpload} />
    </FormGroup>
    {
      reviewsImportFile ? <p className={"alert alert-success"}>
        Thank you! Your data import request is being processed. We will update you once the import is completed
      </p> : null
    }
  </>
}

