// import axios from "axios";

import {getHttpClient} from '../api';
import axios from 'axios';

export function getCurrentQueryObject(){
  const map = {};
  if(window.location.search) {
    window.location.search.substr(1).split("&").map((item) => {
      return item.split("=");
    }).forEach((x) => {
      if (x[1]) {
        map[x[0]] = decodeURIComponent(x[1].trim());
      }
    })
  }
  return map;
}

export async function reloadScriptOnShopify(){
  return getHttpClient().post('/admin/housekeeping/refresh_widget').then(({data})=>data)
      .catch((e)=>{
        console.log('error',e)
  })
}


export const reorder = (list, startIndex, endIndex) => {
  if(startIndex!==undefined && endIndex !== undefined) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }else{
    return list;
  }
};


/**
 * @param file
 * @return {Promise<Object>}
 */
export async function uploadFile(file){
  const {fields, url} = await getHttpClient().post('/admin/preSignedUrl',{
    name: file.name,
  }).then(({data})=>data)
  const f = new FormData();
  console.log(fields, url);
  Object.keys(fields).forEach((k)=> f.append(k, fields[k]))
  f.append('content-type',file.type)
  f.append('file',file)
  return axios.post(url, f)
  .then(({data})=>{
    return {
      url:`https://${fields.bucket}/${fields.key}`,
      type: file.type,
      size: file.size,
    }
  })
}


export function buildUrl(base, queryObject){
  const keys = Object.keys(queryObject);
  const str = keys.map((key)=>{
    return `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
  });
  return `${base}?${str.join("&")}`;
}

export function shopifyRedirect(url){
  if(window.self === window.top) {
    window.location = url;
  }else{
    window.top.location.href = url
  }
}



export function monthAgo(){
  var d = new Date();
  d.setMonth(d.getMonth() - 1);
  return d;
}


export function getReviewLink(integration = "shopify"){
  switch (integration) {
    case "shopify":
      return "https://apps.shopify.com/goaffpro#modal-show=ReviewListingModal&utm_campaign=installed&utm_content=contextual&utm_medium=shopify&utm_source=admin"
    case "wordpress":
      return 'https://wordpress.org/plugins/goaffpro'
      //case "bigcommerce":
      //   return "https://"+window.top.location.host+"/manage/marketplace/apps/16547/reviews/edit"
    default:
      return null;
  }
}


/**
 * @param string
 * @param replacerObject
 */
export function replaceStrings (string, replacerObject) {
  // cleanup object
  if (replacerObject) {
    Object.keys(replacerObject).forEach((item) => {
      if (replacerObject[item] === undefined) {
        replacerObject[item] = ''
      }
    })
  }

  if (replacerObject && string) {
    let str = string
    Object.keys(replacerObject).forEach((item) => str = str.replace(new RegExp(`{${item}}`, 'g'), replacerObject[item]))
    return str
  }
  return string
}


export function reorderArray(list, startIndex, endIndex){
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export function pad(num, paddingLength, paddingWith = "0"){
  return (Array.from({length:paddingLength}).map((item)=>paddingWith).join("")+num).substr(-paddingLength)
}

export function removeUndefined(object){
  Object.keys(object).forEach((item)=>{
    if(object[item] === undefined){
      delete object[item];
    }
  })
  return object;
}

const getExt = (filename = '')=>{
  const parts = filename.split(".")
  return parts && parts.length > 0 ? parts[parts.length-1].toLowerCase() : undefined;
}
const imageExtensions = ['gif','jpeg','jpg','png', 'bmp','webp'];
const videoExtensions = ['mp4','mov','avi','3gp','mkv'];
export const isImage = (url)=>{
  const ext = getExt(url);
  return ext && (imageExtensions.indexOf(ext) > -1)
}

export function getFilename(value){
  const parts = value.split("/")
  return parts[parts.length-1]
}
const {customAlphabet} = require('nanoid');
const nanoid = customAlphabet('qwertyuiopasdfghjklzxcvbnm',10)
export function randomString(){
  return nanoid()
}
