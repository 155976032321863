import React from 'react';
import Radio from '../../../../components/form/Radio';
import FormGroup from '../../../../components/form/FormGroup';
import AsyncProductSelector
  from '../../../../components/product-selector/AsyncProductSelector';
import AsyncCategoriesSelector
  from '../../../../components/product-selector/AsyncCategoriesSelector';

function ApplyToSelector({applyTo, selectedCollections, selectedProducts, onChange}) {
  return (
      <>
        <FormGroup label={"Applies to"}>
          <Radio value={applyTo} onChange={onChange('applyTo')}
                 name={"entireOrder"} label={"Entire Order"}/>
          <Radio value={applyTo} onChange={onChange('applyTo')}
                 name={"selectedProducts"} label={"Selected Products"}/>
          <Radio value={applyTo} onChange={onChange('applyTo')}
                 name={"selectedCollections"}
                 label={"Selected Collections"}/>
        </FormGroup>
        {
          applyTo === "selectedProducts" ? <>
            <AsyncProductSelector isMulti value={selectedProducts} onChange={onChange('selectedProducts')} />
          </> : null
        }
        {
          applyTo === "selectedCollections" ? <>
          <AsyncCategoriesSelector isMulti value={selectedCollections}
                                           onChange={onChange('selectedCollections')} />
          </> : null
        }
      </>
  );
}

export default ApplyToSelector;
