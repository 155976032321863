import React, {useEffect, useState} from 'react';
import {FormattedNumber} from 'react-intl';
import {AiFillStar} from 'react-icons/ai';
import BasicStats from './components/BasicStats';
import RatingDistribution from './components/RatingDistribution';
import {getHttpClient} from '../../api';
import Skeleton from 'react-loading-skeleton';
import RecentReviews from './components/RecentReviews';
import RecentFaqs from './components/RecentFaqs';
import RecentRewards from './components/RecentRewards';
import {Link} from 'react-router-dom';
import {useSettingsStore} from '../../store/settingsStore';
import {FiArrowRight} from 'react-icons/fi';
import LazyWizardIndex from '../setup-wizard/LazyWizardIndex';

function DashboardPage(props) {
  const [period, setPeriod] = useState('24h')
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const marketingIntroCompleted = useSettingsStore((state)=>state.settings.marketingIntroCompleted)
  useEffect(()=>{
    setLoading(true)
    getHttpClient().get('/admin/stats/aggregate',{
      params:{
        period
      }
    })
        .then(({data})=>{
          setLoading(false)
          setData(data)
        })
  },[period])
  if(!marketingIntroCompleted){
    return <LazyWizardIndex />
  }
  return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Link to={"/wizard/step1"}>Restart setup wizard ?</Link>
          </div>
          <div>
            <button className="btn btn-link" disabled={period === '24h'} onClick={()=>setPeriod('24h')}>24hr</button>
            <button className="btn btn-link"  disabled={period === '7d'} onClick={()=>setPeriod('7d')}>7 days</button>
            <button className="btn btn-link"  disabled={period === '30d'} onClick={()=>setPeriod('30d')}>30 days</button>
          </div>
        </div>
        <div className="row gy-3 gx-3 mb-3">
          <StatsCard title={"Points earned"} desc={data.pointsEarned} />
          <StatsCard title={"Points redeemed"} desc={data.pointsSpent}/>
          <StatsCard title={"Total reviews"} desc={data.totalRatings}/>
          <StatsCard title={"Average Rating"} desc={data.averageRating}/>
        </div>
        <div className="row mb-3 gy-3">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <div className={"mb-2"}><span className={"fs-3"}>{data.averageRating} <AiFillStar className={"text-warning"} /></span> based on <strong className={"fs-5"}>{data.totalRatings !== undefined ? <FormattedNumber value={data.totalRatings} />:'-'}</strong> reviews</div>
                <RatingDistribution ratings={data.ratings} />
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <BasicStats />
          </div>
        </div>
        <div className="row gy-3">
          <div className="col-12">
            <div className="card">
              <CardHeader title={"Recent Rewards"} to={"/rewards"} />
              <RecentRewards />
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <CardHeader title={"Recent Reviews"} to={"/product-reviews"} />
              <RecentReviews />
            </div>


          </div>
          <div className="col-md-6">
            <div className="card">
              <CardHeader title={"Recent FAQ"} to={"/faq"} />
               <RecentFaqs />
            </div>
          </div>
        </div>

      </>
  );
}

function CardHeader({title, to}){
  return   <div className="d-flex align-items-center justify-content-between card-header">
                <span>
                 {title}
                </span>
    <Link to={to}>See all<FiArrowRight className={'ms-1'} /></Link>
  </div>
}

function StatsCard({title, desc, loading}){
  return   <div className="col-md-3"><div className="card bg-light">
    {
      loading ?
          <Skeleton height={95.6}/> :
          <div className="card-body">
            <small className={'text-muted'}>{title}</small>
            <div className={"fs-3"}>{desc !== undefined ? <FormattedNumber value={desc} /> : '-'}</div>
          </div>
    }
  </div></div>
}


export default DashboardPage;
