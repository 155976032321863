import React, {useCallback, useEffect, useState} from 'react';
import SettingsSection from '../../components/SettingsSection';
import Colorpicker from '../../components/Colorpicker';
import FormGroup from '../../components/form/FormGroup';
import {useSettingsStore} from '../../store/settingsStore';
import {Link, Route, Routes} from 'react-router-dom';
import {AiOutlineQuestionCircle} from 'react-icons/ai';
import StoreLanguageSelector
  from '../settings/general-components/StoreLanguageSelector';
import useLocaleState from '../../components/hooks/useLocaleState';
import AvailableLanguageSelector
  from '../../components/locale/AvailableLanguagesSelector';
import CodeEditor from '../../components/code-editor/CodeEditor';
import Button from '../../components/form/Button';
import useToggle from '../../components/hooks/useToggle';

function ThemeEditorIndex(props) {
    return <Routes>
          <Route index element={<WidgetSetup />} />
    </Routes>
}


function WidgetSetup(){

  const theme = useSettingsStore((state)=>state.settings.widgetTheme)
  const updateTheme = useSettingsStore((state)=>state.updateTheme)
  const httpUpdate = useCallback((key)=>{
    return (value)=>{
      return updateTheme({[key]:value})
    }
  },[updateTheme])

  const [currentLocale, setCurrentLocale] = useLocaleState()

  return <>
    <div className="row gy-2 border-bottom">
      <div className="col-md-6">
        <h2>Widget Integration and Styles</h2>
        <p className="text-muted">Customize and Integrate various widgets on your store</p>
      </div>
      <div className="col-md-6">
        <Link to={"/wizard/concierge"} className="btn btn-link">
          <AiOutlineQuestionCircle className={"me-2"} />Get help from qualified engineer</Link>
      </div>
    </div>
    <SettingsSection title={"Basic Styles"} subtitle={"These colors are used to customize general theme of the widgets and emails"}>
        <FormGroup label={"Brand primary color"}>
          <Colorpicker value={theme.brandPrimaryColor} onChange={httpUpdate('brandPrimaryColor')} />
        </FormGroup>
      <FormGroup label={"Star rating color"}>
        <Colorpicker value={theme.psrStarColor} onChange={httpUpdate('psrStarColor')} />
      </FormGroup>
      <div className="row">
        <div className="col-6">
          <FormGroup label={"Button background color"}>
            <Colorpicker value={theme.btnPrimaryBg} onChange={httpUpdate('btnPrimaryBg')} />
          </FormGroup>
          </div>
        <div className="col-6">
        <FormGroup label={"Button text color"}>
            <Colorpicker value={theme.btnPrimaryColor} onChange={httpUpdate('btnPrimaryColor')} />
          </FormGroup>
        </div>
      </div>
    </SettingsSection>
    <SettingsSection title={"Widget Language"} subtitle={<>The language in which the widget content is shown<br/><Link to={"/locale"}>Edit translations</Link></>}>
      <StoreLanguageSelector />
    </SettingsSection>

    <SettingsSection title={"Integration guides"} subtitle={"Ways to integrate the widgets in your store"}>
      <ul className="list-group">
        <Link to={"/wizard/product-reviews/code-integration"} className={"list-group-item list-group-item-action"}>Rating and Reviews widgets</Link>
        <Link to={"/wizard/loyalty-setup/code-integration"} className={"list-group-item list-group-item-action"}>Loyalty Program widget</Link>
      </ul>
  </SettingsSection>
    <SettingsSection title={"Widget Editor"} subtitle={<>Customize the widgets <br/><AvailableLanguageSelector value={currentLocale} onChange={setCurrentLocale} /> </>}>
      <ul className="list-group">
        <Link to={"/widget-editor/BasicTheme"} className={"list-group-item list-group-item-action"}>Basic Widget Styles</Link>
        <Link to={"/widget-editor/ProductPageStarRating"} className={"list-group-item list-group-item-action"}>Product page: Star Rating widget</Link>
        <Link to={"/widget-editor/CollectionPageStarRating"} className={"list-group-item list-group-item-action"}>Collection page: Star Rating widget</Link>
        <Link to={"/widget-editor/ReviewAndFAQ"} className={"list-group-item list-group-item-action"}>Reviews and FAQ</Link>
        <Link to={"/widget-editor/LoyaltyProgramLanding"} className={"list-group-item list-group-item-action"}>Loyalty Program: Landing page</Link>
        <Link to={"/widget-editor/LoyaltyProgramDashboard"} className={"list-group-item list-group-item-action"}>Loyalty Program: Customer Dashboard</Link>
        <Link to={"/widget-editor/LoyaltyCouponPopup"} className={"list-group-item list-group-item-action"}>Loyalty Program: Coupon popup</Link>
        <Link to={"/widget-editor/LoyaltyProgramNotifier"} className={"list-group-item list-group-item-action"}>Loyalty Program: Visitor notification</Link>

      </ul>
    </SettingsSection>
    <AdvancedEditor />
  </>
}

function AdvancedEditor(){
  const [open, toggle] = useToggle(false)
  const [css, setCustomCss] = useState('')
  const [loading, setLoading] = useState(false)
  const customCss = useSettingsStore((state)=>state.settings.customCss || '')
  useEffect(()=>{
    setCustomCss(customCss)
  },[customCss])
  const updateSettings = useSettingsStore((state)=>state.updateSettings)
  const onSubmit = useCallback(()=>{
    setLoading(true)
    return updateSettings({
      customCss: css
    }).then(()=>{
      setLoading(false)
    })
  },[css, updateSettings])
  return   <SettingsSection title={"Advanced Styling"} subtitle={"Add custom css code to the widgets"}>

    {
      open ? <>
        <FormGroup label={"Custom css"}>
          <div className="border">
            <CodeEditor defaultLanguage={"css"} onChange={setCustomCss} value={css} />
          </div>
        </FormGroup>
        {
          customCss !== css || loading ?
              <Button onClick={onSubmit} loading={loading}
                      className="btn btn-primary">Submit</Button> : null
        }
      </> :  <button onClick={toggle} className="btn btn-link">Open CSS Editor</button>
    }
  </SettingsSection>
}



export default ThemeEditorIndex;
