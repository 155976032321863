import React from 'react';
import {FiPlayCircle} from 'react-icons/fi';
function VideoThumbnail({width, height, src}) {
  return (
      <div
          className={"d-inline-flex shadow-sm rounded overflow-hidden align-items-center justify-content-center position-relative"}
          style={{width, height}}>
        <img src={src} className="w-100 h-100 position-absolute bg-light"
             style={{objectFit: 'cover'}} alt=""/>
        <FiPlayCircle className={"text-secondary"} style={{fontSize:32, zIndex:1}}/>
      </div>
  );
}

export default VideoThumbnail;
