import {useCallback, useEffect} from 'react';
import {useSettingsStore} from '../../store/settingsStore';
import create from 'zustand';
import {getHttpClient} from '../../api';

export const useLocaleStore = create((set, get)=>{
  return {
    currentLocale: null,
    data:{},
    setLocale:(l)=>{
      set({
        currentLocale: l
      })
      console.log(get().data)
      if(!get().data[l]){
        return get().getLocaleData(l)
      }
    },
    getLocaleData:(lang)=>{
      return getHttpClient().get('/admin/sites/locale_dictionary/' + lang)
          .then(({data})=>{
            const orig = get().data
            const d =  {...orig, [lang]: data.locale}
            set({data:d})
            return d
        })
    },
    updateLocaleData:(lang, data)=>{
      return getHttpClient().post('/admin/sites/locale_dictionary/'+lang,{
        dictionary: data,
      }).then(({data})=>{
        const orig = get().data
        set({data: {...orig, [lang]: data.locale}})
      })
    }
  }
})

const reducer =  (state)=>[state.currentLocale, state.setLocale]

function useLocaleState(props) {
  const [stored, setStoredLocale] = useLocaleStore(reducer)
  const defaultLocale = useSettingsStore((state)=>state.settings.defaultLocale)

  useEffect(()=>{
    if(!stored){
      setStoredLocale(defaultLocale)
    }
  },[defaultLocale, setStoredLocale, stored])
  const onChange = useCallback((value)=>{
    setStoredLocale(value)
  },[setStoredLocale])
  return [stored, onChange]
}

export default useLocaleState;
