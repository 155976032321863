import axios from 'axios';

export const baseURL = process.env.NODE_ENV === "development" ? 'http://localhost:8081':'https://api.uxbundle.com';
export function getHttpClient(){
  const headers  = {};
  if(localStorage.getItem('access_token')){
    headers.Authorization = "Bearer "+localStorage.getItem('access_token')
  }
  return axios.create({
    baseURL,
    headers
  })
}


export function loadFaqs(limit, offset, filter){
  return gqlQuery(`query ($limit: Int, $offset:Int, $filter: FaqFilter){
      faqs(limit: $limit, offset:$offset, filter: $filter){
        data {
          _id
          question
          answer
          status
          answeredAt
          createdAt
          customer {
            _id
            firstName
            lastName
            emailAddress
          }
          product {
            name
          }
        }
        count
        limit
        offset
    }
    }`, {
    limit, offset,
    filter,
  })
}


export function gqlQuery(query, variables){
  return getHttpClient().post('/admin/graphql',{
      query,
      variables
  }).then(({data})=>data.data)
}

export function getFetcher(endpoint, params){
  return getHttpClient().get(endpoint, {params}).then(({data})=>data)
}

