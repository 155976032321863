import React from 'react'
import PropTypes from 'prop-types';
const Cmp = React.lazy(()=>import('./AsyncCategoriesSelectorInternal'))
const AsyncCategoriesSelector = (props)=><React.Suspense fallback={null}><Cmp {...props} /></React.Suspense>

AsyncCategoriesSelector.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool
}

export default AsyncCategoriesSelector
