import React, {useCallback, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import FacebookLoginButton from '../../components/auth/FacebookLoginButton';
import {getHttpClient} from '../../api';
import FormGroup from '../../components/form/FormGroup';
import Form from '../../components/form/Form';
import Input from '../../components/form/Input';
import Button from '../../components/form/Button';

function StoreLogin(props) {
  const [userAccessToken, setUserAccessToken] = useState(new URL(document.location.href).searchParams.get('token'))
  const navigate = useNavigate()
  let from = new URL(document.location.href).searchParams.get('from')

  useEffect(()=>{
    if(userAccessToken) {
      localStorage.setItem('access_token', userAccessToken)
      navigate(from || "/", {replace: true})
    }
  },[from, navigate, userAccessToken])

  return (
      <div className="bg-light min-vh-100">
        <div className="container" style={{paddingTop:80}}>
          <div className="row">
            <div className="col-md-3 col-lg-4"/>
            <div className="col-md-6 col-lg-4">
              <div className="text-center">
                <span style={{fontSize:30, fontFamily:"'Archivo Black', sans-serif"}}>UXBUNDLE</span>
              </div>
              <div className={"card mb-2"}>
                <div className="card-body">
                  <h5>Login to continue</h5>
                  <PasswordAuth onLogin={setUserAccessToken} />
                  <div className="mb-4 mt-4">
                    <OrDivider />
                  </div>
                  {/*
                    <FacebookLoginButton onLogin={setUserAccessToken}/>
                  */}
                  <Link className={"btn btn-outline-light border text-dark mt-2 py-2 d-flex align-items-center justify-content-center"} to={"/install/shopify/login"}>
                    Login with <img alt={"Shopify"} src={"/logos/shopify-2.svg"} className={"ms-3"} style={{height:32}}/>
                  </Link>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <small>
                  <a href={"/terms"} className={"text-decoration-none"}>Terms and Conditions</a></small>
                <small>
                  <a href={"/privacy"}  className={"text-decoration-none"}>Privacy Policy</a>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

function OrDivider(){
  return <div className="row align-items-center">
    <div className="col">
      <hr className={"text-muted"}/>
    </div>
    <div className="col-auto">OR</div>
    <div className="col">
      <hr className={"text-muted"}/>
    </div>
  </div>
}

function PasswordAuth({onLogin}){
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const onSubmit = useCallback(()=>{
    setLoading(true)
    return   getHttpClient().post('/auth/email',{
      email,
      password
    }).then(({data})=>{
      setLoading(false)
      if(data.error){
        setError(data.error)
      }else if(data.access_token){
        onLogin(data.access_token)
      }
    })
  },[email, onLogin, password])

  return <Form onSubmit={onSubmit}>
    {
      error ? <p className="alert alert-danger">{error}</p> : null
    }
    <FormGroup label={"Email address"}>
      <Input onChange={setEmail} value={email} />
    </FormGroup>
    <FormGroup label={"Password"}>
      <Input type={"password"} onChange={setPassword} value={password} />
    </FormGroup>
    <Button loading={loading} className="btn btn-primary w-full">Login</Button>
  </Form>
}



export default StoreLogin;
