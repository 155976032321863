import React from 'react'
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton'
const Cmp = React.lazy(()=>import('./RangePicker'))
const AsyncCategoriesSelector = (props)=><React.Suspense fallback={<Skeleton height={36}/>}><Cmp {...props} /></React.Suspense>

AsyncCategoriesSelector.propTypes = {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    isMulti: PropTypes.bool
}

export default AsyncCategoriesSelector
