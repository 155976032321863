import React, {useCallback} from 'react';

function Input(props) {
  const onChange = props.onChange
  const changed = useCallback((e)=>{
    if(onChange){
      onChange(e.target.value)
    }
  },[onChange])
  return (
      <input className={"form-control"} {...props} onChange={changed}/>
  );
}

export default Input;
