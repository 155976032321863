import React from 'react';
import {Routes, Route} from 'react-router-dom'
import StoreLogin from './StoreLogin';
import ManagerAccountSetup from './ManagerAccountSetup';
import CreateShopifyPrivateApp from './CreateShopifyPrivateApp';
function LoginIndex(props) {
  return (
      <Routes>
        <Route path={"a"} element={<ManagerAccountSetup />}/>
        <Route index element={<StoreLogin />} />
        <Route path={"/shopify-private"} element={<CreateShopifyPrivateApp />} />
      </Routes>
  );
}

export default LoginIndex;
