import create from 'zustand';
import {getHttpClient} from '../api';

export const useSettingsStore = create((set,get)=>{
  return {
    settings: null,
    loading: false,
    loadSettings: ()=>{
      set({loading:true})
      return getHttpClient().get('/admin/settings').then(({data})=>set({loading:false, settings: data.settings}))
    },
    mergeSettings:(change)=>{
      const orig = get().settings
      set({settings:{...orig, ...change}})
    },
    updateSettings: (change)=>{
      const orig = get().settings
      set({settings:{...orig, ...change}})
      return getHttpClient()
      .patch('/admin/settings', {change})
      .then(({data})=>set({settings: {...get().settings, ...change }}))
          .catch((e)=>{
            console.log(e)
            set({settings: orig})
      })
    },
    updateTheme:(change)=>{
      const orig = get().settings
      set({settings:{...orig, widgetTheme: {...orig.widgetTheme, ...change}}});
      return getHttpClient()
          .patch('/admin/settings/widgetTheme', {change})
          .then(({data})=>set({settings: {...get().settings, ...data }}))
          .catch((e)=>{
            set({settings: orig})
          })
    }
  }
})

const initialState = {}
for ( var i = 0, len = localStorage.length; i < len; ++i ) {
  initialState[localStorage.key(i)] = localStorage.getItem(localStorage.key(i))
}

export const useLocalStorage = create((set, get)=>{
  return {
    data:initialState,
    setItem:(key, value)=>{
      localStorage.setItem(key, value)
      set({data: {...get().data, [key]:value}})
    },
    getItem:(key)=>{
      return get().data[key]
    }
  }
})
