import React, {useCallback, useEffect, useState} from 'react';
import Modal from '../components/Modal';
import FormGroup from '../components/form/FormGroup';
import Input from '../components/form/Input';
import Colorpicker from '../components/Colorpicker';
import {useDropzone} from 'react-dropzone';
import {FiPackage, FiUpload} from 'react-icons/fi';
import {reloadScriptOnShopify, uploadFile} from '../utils/func';
import Spinner from '../components/form/Spinner';
import {useSettingsStore} from '../store/settingsStore';
import ReactSwitch from 'react-switch';

function Pwa(props) {
  const [visible, setVisible] = useState(false)
  const {
      pwa_manifest,
      pwa_manifest_enabled,
      updateSettings,
  } = useSettingsStore((state)=>{
    const { pwa_manifest, pwa_manifest_enabled} = state.settings;
    return {
      pwa_manifest,
      pwa_manifest_enabled,
      updateSettings: state.updateSettings
    }
  })

  return (
      <>
        <div className="row">
          <div className="col-md-6">
            <h1>Installable App</h1>
            <p>Convert your website into an installable app which can be installed on your store visitor's mobile</p>
          </div>
          <div className="col-md-6">
            <ReactSwitch checked={pwa_manifest_enabled} onChange={({pwa_manifest_enabled})=>{
              updateSettings({pwa_manifest_enabled}).then(r => ()=>{
                return reloadScriptOnShopify();
              })
            }} />
          </div>
        </div>
        <div className="border rounded overflow-hidden" style={{width:256, maxWidth:'100%'}}>
          <div className={"d-flex align-items-center justify-content-center"} style={{height:256}}>
          {
            pwa_manifest && pwa_manifest.app_icon && pwa_manifest.app_icon.src ? <>
              <img style={{width:238, height:238, borderRadius:18,  objectFit:'cover'}} className={"shadow-sm"} src={pwa_manifest.app_icon.src}

                   alt="App icon"/>
            </> : <FiPackage size={128} style={{strokeWidth:0.5}} className={"text-muted"} />
          }
          </div>
          <div className={"text-center py-3"}>{pwa_manifest ? pwa_manifest.name : 'App Name'}</div>
          <div className="m-2">
            <button onClick={()=>setVisible(true)} className="w-100 btn btn-primary">Edit Settings</button>
          </div>

        </div>
        <EditManifest onClose={()=>setVisible(false)} visible={visible} />
      </>
  );
}

function EditManifest({visible, onClose}){
  const [saving, setSaving] = useState(false)
  const [manifest,setManifest] = useState({
    name:'',
    short_name:'',
    theme_color:'#ffffff',
    background_color:'#ffffff',
    app_icon:'',
  })

  const [settingsManifest, updateSettings] = useSettingsStore((state)=>[state.settings ? state.settings.pwa_manifest : {}, state.updateSettings])

  useEffect(()=>{
      if(settingsManifest){
          setManifest(settingsManifest)
      }
  },[settingsManifest])

  function onSubmit(e){
    if(e){
      e.preventDefault();
      e.stopPropagation();
    }
    setSaving(true)
    updateSettings({
      pwa_manifest: manifest,
      pwa_manifest_enabled:true,
    }).then(()=>{
      setSaving(false)
      reloadScriptOnShopify()
      onClose()
    })

  }

  const changed = useCallback((key)=>{
      return (value)=>setManifest((m)=>{return {...m, [key]:value}})
  },[])
  return <Modal confirmLoading={saving} onSubmit={onSubmit} okText={"Submit"} visible={visible} onClose={onClose}>
    <FormGroup label={"App Icon"} info={"Minimum 512x512 px. Must be square aspect ratio (1:1)"}>
      <AppIconUploader value={manifest.app_icon} onChange={changed('app_icon')} />
    </FormGroup>
    <div className="row">
      <div className="col-6">
        <FormGroup label={"App name"} info={"Name of the app displayed in the user's app drawer"}>
          <Input value={manifest.name} onChange={changed('name')} />
        </FormGroup>
      </div>
      <div className="col-6">
        <FormGroup label={"Short app name"} info={"Displayed in the user's screen if full name can't be displayed"}>
          <Input value={manifest.short_name} onChange={changed('short_name')} />
        </FormGroup>
      </div>
    </div>
    <hr/>
    <div className="row">
      <div className="col-6">
        <FormGroup label={"Theme color"} info={"Color of the URL/Navigation bar when the app is launched"}>
          <Colorpicker value={manifest.theme_color} onChange={changed('theme_color')} />
        </FormGroup>
      </div>
      <div className="col-6">
        <FormGroup label={"Background color"} info={"Color of the splashscreen when the app is launching"}>
          <Colorpicker value={manifest.background_color} onChange={changed('background_color')} />
        </FormGroup>
      </div>
    </div>

  </Modal>
}

function AppIconUploader({value, onChange}){
  const [uploading, setUploading] = useState(false)
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    setUploading(true)
    uploadFile(acceptedFiles[0])
    .then(({type, url})=>{
      onChange({
        src: url,
        type
      })
      setUploading(false)
    })
    console.log(acceptedFiles[0])

  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept:"image/jpeg, image/png,image/webp, image/svg",
    maxFiles:1,
  })
  return (
      <div>
      <div {...getRootProps()} style={{width:128, height:128, borderRadius:18}}>
        <input {...getInputProps()} />
        <div style={{width:128, height:128, borderRadius:18, overflow:'hidden'}}
             className={`text-secondary shadow-sm  d-flex align-items-center justify-content-center border ${isDragActive ? 'border-primary' : ''}`}>
          {
            uploading ? <Spinner className={"text-primary"} size={32}/> : <>{
              value ? <img src={value.src} style={{width:'100%',height:'100%', objectFit:'cover'}} alt={"App icon"} /> :
                <FiUpload style={{width: 32, height: 32, strokeWidth: 1}}/>
              }</>
          }
        </div>

      </div>
        {
          value ? <button onClick={()=>onChange(null)} className={"btn-sm px-0 btn btn-link text-danger"}>Delete</button> : null
        }
      </div>
    )
}

export default Pwa;
