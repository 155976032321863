import React, {useCallback, useEffect, useState} from 'react';
import {getHttpClient} from '../../../api';
import Skeleton from 'react-loading-skeleton';
import {FormattedNumber} from 'react-intl';
import {useSettingsStore} from '../../../store/settingsStore';
import {Link} from 'react-router-dom';
import {removeBilling} from '../../../config';

export default function BasicStats(){
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const planName = useSettingsStore((state)=>state.settings.planName)
  useEffect(()=>{
    setLoading(true)
    getHttpClient().get('/admin/stats/basic_stats')
        .then(({data})=>{
          setLoading(false)
          setData(data)
        })
  },[])
  if(!data) return null
  return  <div className="card flex-grow-1">
    <div className="card-header">
      General information
    </div>
    <ul className="list-group list-group-flush">
      {
        loading ? <Skeleton height={32} count={4}/> : <>
          {
            !removeBilling ?
                <StatItem title={'Plan'}>
                  <Link to={"/billing"}>{planName ?
                      planName.toUpperCase() :
                      ''}</Link>
                </StatItem> : null
          }
          <StatItem title={'Enrolled customers'}><FormattedNumber
              value={data.customers}/></StatItem>
          <StatItem title={'Total points'}><FormattedNumber
              value={data.points}/></StatItem>
          <StatItem title={'Total reviews'}><FormattedNumber
              value={data.reviews}/></StatItem>
          <StatItem title={'Total questions'}><FormattedNumber
              value={data.faq}/></StatItem>
          <LoyaltyProgramPage />
        </>
      }
    </ul>
  </div>
}
function LoyaltyProgramPage(){
  const shopifyLoyaltyPageHandle = useSettingsStore(useCallback((state)=>state.settings.shopifyLoyaltyPageHandle,[]))
  const website = useSettingsStore(useCallback((state)=>state.settings.website,[]))

  if(shopifyLoyaltyPageHandle){
        return  <StatItem title={'Loyalty program'}><a target={"_blank"} href={`https://${website}/pages/${shopifyLoyaltyPageHandle}`}>Open Page</a></StatItem>
  }else{
    return null;
  }
}

function StatItem({title, children}){
  return   <li className="list-group-item d-flex align-items-center justify-content-between">
    <span>{title}</span>
    <span>{children}</span>
  </li>
}
