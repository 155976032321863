import {customAlphabet} from 'nanoid';
export const nanoid = customAlphabet('qwertyuiopasdfghjklzxcvbnm', 8);
export const handleize = (content)=> content.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/-$/, '').replace(/^-/, '')
export const shallowCompare = (obj1, obj2) =>
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every(key => obj1[key] === obj2[key]);
export const shallowCompareSubset = (obj1, obj2) => Object.keys(obj1).every(key => obj1[key] === obj2[key]);
export function getThumbnailForImage(src, params){
  if(src.startsWith('https://media.uxbundle.com')){
    return src+'?'+params;
  }else{
    return src;
  }
}
export function getExt(filename){
  return filename.split('.').pop().toLowerCase();
}
const   emailValidator = new RegExp(/\S+@\S+\.\S+/);
export function isValidEmail(email){
    return emailValidator.test(email)
}
