import React from 'react';

function Avatar({src, size, name}) {
  return (
      <>
        {
          src ? <img src={src} style={{borderRadius:'50%',width:size, height:size, objectFit:'cover'}} alt={name} title={name} /> :
              <div
                  className={"bg-secondary rounded d-inline-flex align-items-center justify-content-center text-light"} style={{width:size, height:size}}>
                {name ? name.substring(0,2).toUpperCase() : '--'}
              </div>
        }
      </>
  );
}

export default Avatar;
