import React, {useCallback} from 'react';

function Select({value, options, style, onChange, hasEmpty}) {
  const changed = useCallback((e)=>{
    if(onChange){
      onChange(e.target.value)
    }
  },[onChange])

  return (
      <select className={"form-control"} style={style} value={value} onChange={changed}>
        {
          hasEmpty ? <option/> : null
        }
        {
          options.map(({label, value})=>{
            return <option value={value} key={value}>{label}</option>
          })
        }
      </select>
  );
}

export default Select;
