import React from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

const CodeEditorCmp = React.lazy(()=>import('./CodeEditorInternal'))
function CodeEditor(props){
  return <React.Suspense fallback={<Skeleton height={300} />}><CodeEditorCmp {...props} /></React.Suspense>
}
CodeEditor.propTypes  = {
  defaultLanguage:PropTypes.oneOf(['css','javascript']),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  height: PropTypes.string.isRequired,
}
CodeEditor.defaultProps = {
  height:'300px',
}

export default CodeEditor
