import React from 'react';
import Spinner from './Spinner';

function Button({className, type, title, disabled, loading, onClick, children}) {
  return (
      <button type={type} className={className} disabled={loading || disabled} onClick={onClick}>
        {
          loading ? <span><Spinner className={"me-1"} />{title} {children}</span>: <span>{title} {children}</span>
        }
      </button>
  );
}

export default Button;
