import {BsArchive} from 'react-icons/bs';
import React from 'react';

function EmptyCard(){
  return <>
    <div className="text-center d-flex align-items-center justify-content-center text-muted p-4 bg-light" style={{height:150}}>
      <div>
        <BsArchive className={"fs-1"} />
        <div className="text-center">
          <span>No data</span>
        </div>
      </div>
    </div>
  </>
}

export default EmptyCard
