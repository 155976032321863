import React from 'react';
import {useSettingsStore} from '../store/settingsStore';
import {FormattedNumber} from 'react-intl';

function RenderCurrencyNumber({value}) {
  const defaultCurrency = useSettingsStore((state)=>state.settings.defaultCurrency || 'USD')
  if(Number(value) % 1 === 0){
    return   <FormattedNumber value={value} style={'currency'}
                              minimumFractionDigits={0}
                              currency={defaultCurrency}/>
  }else{
    return <FormattedNumber value={value} style={'currency'} currency={defaultCurrency}/>
  }
}

export default RenderCurrencyNumber;
