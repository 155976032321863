import React, {useCallback, useMemo} from 'react';
import Sidebar from './Sidebar';
import {Link, Outlet} from 'react-router-dom';
import {useSettingsStore} from '../../store/settingsStore';
import useAuth from '../hooks/useAuth';
import Select from '../form/Select';

function SiteSelector(){
  const [sites, siteId, setCurrentSite] = useAuth(useCallback((state)=>{
    return [state.sites, state.siteId, state.setCurrentSite]
  },[]));
  const opts = useMemo(()=>{
    return sites ? sites.map((item)=>{
      return {
        label: item.name,
        value: item._id
      }
    }) : []
  },[sites])
  if(opts.length === 1) return null
  return <div style={{width:200}}><Select value={siteId} options={opts} onChange={(value)=>{
    setCurrentSite(opts.findIndex((item)=>item.value==value))
  }}/></div>
}

function DashboardContainer({children}) {
  return (
      <div className={"container-fluid d-flex flex-column p-0 min-vh-100"}>
          <header className={'d-flex justify-content-between align-items-center bg-dark text-light px-4 py-3'}>
            <span style={{fontSize:24, fontFamily:"'Archivo Black', sans-serif"}}>UXBUNDLE</span>
            <SiteSelector />
          </header>
         <SetupRequestOngoing />
        <div className="d-md-none">
          <Sidebar/>
        </div>
        <main className={"flex-fill d-flex"}>
          <div className="row g-0 flex-fill">
            <div className="d-none d-md-block col-md-4 col-lg-3 col-xl-2">
              <Sidebar/>
            </div>
            <div className="col-md-8 col-lg-9 col-xl-10">
              <div className="px-3 py-2">
                <Outlet />
              </div>
            </div>
          </div>
        </main>
        <footer>
        </footer>
      </div>
  );
}

function SetupRequestOngoing(){
  const setupRequestsPending = useSettingsStore(useCallback((state)=>state.settings.setupRequestsPending,[]))
  const needsSetup = setupRequestsPending && setupRequestsPending.length > 0
  if(needsSetup){
    return <>
      <div className="bg-secondary d-flex align-items-center justify-content-center text-light py-2 border-bottom text-center">
        <span>Your setup request is underway. We will let you know when the setup is finished.</span>
        <Link to={"/wizard/concierge"}  className={" btn p-0 btn-link text-light mx-1"}>Click here</Link>
        <span>to manage the request</span>
      </div>
    </>
  }else{
    return null;
  }
}

export default DashboardContainer;
