import React from "react";
import {FormattedMessage, useIntl} from 'react-intl';

export default function FormGroup({label, labelValues, htmlFor, children, required, info, error}){
  const intl = useIntl()

  return <div className={"mb-2"}>
    {
      label &&
      <label className={"pb-1"} htmlFor={htmlFor}>
            <small className={"font-weight-bold"}>
              <FormattedMessage id={label} defaultMessage={label}
                                values={labelValues} />
            </small>
        {
          required ? <span className={"text-danger ms-1"}>*</span> : null
        }
      </label>
    }
    {children}
    {
      info && !error &&
      <small className="text-muted">
        {info}
      </small>
    }
    {
      error &&
      <small className="text-danger">
        {intl.formatMessage({id: error, defaultMessage: error})}
      </small>
    }
  </div>
}
