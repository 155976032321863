import {useCallback, useEffect, useState} from 'react';

export function useWindowMessageListener(){
  const [message, setMessage] = useState(null)
  const listener = useCallback((e)=>{
      setMessage(e && e.data)
  },[setMessage])
  useEffect(()=>{
    window.addEventListener('message', listener)
    return ()=>{
      window.removeEventListener('message', listener)
    }
  },[])
  return message;
}
const oauth_proxy = "https://api.uxbundle.com/oauth.html"
const facebookAuthConfig = {
  oauth_proxy,
  client_id: '275122491203888',
  callback_uri: 'https://api.uxbundle.com/oauth-callback.html?provider=facebook'
};
let currentOpened = null
export function useFacebookLogin(){
  let authorize = facebookAuthConfig.oauth_proxy + '?';
  authorize += `callback_uri=${encodeURIComponent(facebookAuthConfig.callback_uri)}&`;
  authorize += `proxy_uri=`;
  authorize += encodeURIComponent(`https://www.facebook.com/v3.2/dialog/oauth?response_type=token&client_id=${facebookAuthConfig.client_id}&redirect_uri=${facebookAuthConfig.callback_uri}&scope=email`);
  if(currentOpened){
    currentOpened.close()
  }
  currentOpened = window.open(authorize, 'mywin','width=500,height=600');
}

export function useForceReload(){
  const [forceReload, setForceReload] = useState(0)
  const doForceReload = useCallback(()=>{
    setForceReload((f)=>f+1)
  },[])
  return [forceReload, doForceReload]
}
