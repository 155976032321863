import React, {useEffect, useState} from 'react';
import './App.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import DashboardContainer from './components/layout/DashboardContainer';
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {useSettingsStore} from './store/settingsStore';
import useWidgetStore from './components/hooks/useWidgetStore';
import LazyInstallers from './pages/installers/LazyInstallers';
import useAuth from './components/hooks/useAuth';
import Spinner from './components/form/Spinner';
import {getHttpClient} from './api';
import Logout from './pages/Logout';
import Pwa from './pages/Pwa';
import Settings from './pages/settings/LazySettings';
import DashboardPage from './pages/dashboard/DashboardPage';
import EmailTemplatesIndex
  from './pages/email-template-editor/EmailTemplatesIndex';
import EmailTemplatesContainer
  from './pages/email-template-editor/EmailTemplatesContainer';
import LocaleEditor from './pages/settings/locale/LocaleEditor';
import CustomersIndex from './pages/customers/CustomersIndex';
import FAQIndex from './pages/faq/FAQIndex';
import ReviewsIndex from './pages/reviews/ReviewsIndex';
import Contact from './pages/Contact';
import ThemeEditorIndex from './pages/theme-editor/ThemeEditorIndex';
import RewardsIndex from './pages/rewards/RewardsIndex';
import LoginIndex from './pages/auth/LoginIndex';
import LazySysAdminIndex from './sys-admin/LazySysAdminIndex';
import LazyUpgradeIndex from './pages/upgrade/LazyUpgradeIndex';
import LazyWizardIndex from './pages/setup-wizard/LazyWizardIndex';
import LazyLoyaltyIndex from './pages/loyalty/LazyLoyaltyIndex';
import LazyWidgetEditor from './pages/theme-editor/LazyWidgetEditor';
import LazyEmailTemplateEditorIndex
  from './pages/email-template-editor/LazyEmailTemplateEditorIndex';
import LazyAnalyticsIndex from './pages/analytics/LazyAnalyticsIndex';
import InstagramIndexLazy from './pages/instagram/InstagramIndexLazy';
import LazyAutomationIndex from './pages/automation/LazyAutomationIndex';
import LazyOrdersIndex from './pages/orders/LazyOrdersIndex';
import LazyFormsIndex from './pages/forms/LazyFormsIndex';

function AuthenticatedArea(){
  const navigate = useNavigate();
  const [complete, setCompleted] = useState(false)
  const [init, initialized,  sites, userId, siteId, setCurrentSite, error] = useAuth((state)=>{
    return [state.init, state.initialized, state.sites, state.userId, state.siteId, state.setCurrentSite, state.error]
  })
  const [settings, loadSettings] = useSettingsStore((state)=>[state.settings, state.loadSettings]);
  const [widgets, loadWidgets] = useWidgetStore((state)=>[state.widgets, state.loadWidgets])
  useEffect(()=>{
    console.log({initialized})
    if(!initialized) {
      init()
    }
  },[init, initialized])
  const [searchParams] = useSearchParams()
  useEffect(()=>{
    if(initialized && !error){
      if(!userId){
        navigate('/auth', {replace:true})
      }else if(!siteId){
        if(!sites || sites.length === 0){
          navigate('/install',{replace:true})
        }else {
          const foundIndex = searchParams.get('siteId') ? sites.findIndex((item)=>item._id == searchParams.get('siteId')) : 0
          setCurrentSite(foundIndex || 0)
        }
      }
    }
  },[initialized, siteId, userId, sites, error, navigate, setCurrentSite, searchParams])

  useEffect(()=>{
    if(siteId){
      Promise.all([loadSettings(), loadWidgets()]).then(()=>{
        getHttpClient().post('/admin/housekeeping/ping')
        .then((data)=>console.log('housekeeping completed'))
        .catch((e)=>{
          console.log('error in housekeeping')
        })
        setCompleted(true)
      })
    }
  },[loadSettings, loadWidgets, siteId])
  if(error) return <div className={"text-center"}>Network error</div>
  if(!complete) return <div className={"text-center"} style={{paddingTop:64}}>
    <p>Just a moment...</p>
    <Spinner size={48} className={"text-primary"} />
  </div>;
  return <>
    <Outlet />
    </>
}

function AppInternal() {
  return (
      <Routes>
        <Route path={"/uxbundle-sysadmin/*"} element={<LazySysAdminIndex />} />
        <Route path={"/install/*"} element={<LazyInstallers />}/>
        <Route path={"/auth/*"} element={<LoginIndex />} />
        <Route path={"/logout"} element={<Logout />} />
        <Route path={"/"} element={<AuthenticatedArea/>}>
          <Route path={"/"} element={<DashboardContainer />}>
            <Route path={"/"} element={<DashboardPage />} />
            <Route path={"automation/*"} element={<LazyAutomationIndex />} />
            <Route path={"analytics/*"} element={<LazyAnalyticsIndex />} />
            <Route path={"sales/*"} element={<LazyOrdersIndex />} />

            <Route path={"billing/*"} element={<LazyUpgradeIndex />} />
            <Route path={"wizard/*"} element={<LazyWizardIndex />} />
            <Route path={"customers/*"} element={<CustomersIndex />} />
            <Route path={"notifications"} element={
              <EmailTemplatesContainer>
                <EmailTemplatesIndex />
              </EmailTemplatesContainer>
            } />
            <Route path={"theme-editor/*"} element={<ThemeEditorIndex />} />

            <Route path={"contact"} element={<Contact />} />
            <Route path={"rewards"} element={<RewardsIndex />} />
            <Route path={"locale"} element={<LocaleEditor />} />
            <Route path={"loyalty/*"} element={<LazyLoyaltyIndex />} />
            <Route path={"settings/*"} element={<Settings />} />
            <Route path={"pwa"} element={<Pwa />} />
            <Route path={"product-reviews"} element={<ReviewsIndex />} />
            <Route path={"faq"} element={<FAQIndex />} />
            <Route path={"instagram/*"} element={<InstagramIndexLazy />}/>
            <Route path={"form/*"} element={<LazyFormsIndex />}/>
            {/*
             <Route path={"messenger"} element={<MiscellaneousWidgets />} />

             <Route path={"security"} element={<SecurityModule />}/>
             <Route path={"geo-redirect"} element={<GeoRedirection />}/>
             <Route path={"notification-bar"}>
              <Route path={"edit/:notificationId"} element={<NotificationEditor />} />
              <Route index element={<NotificationBar />} />
            </Route>

          <Route path={"/scroll-widget"} element={<ScrollWidgetEditor />} />
            */}
        </Route>
          <Route path={"widget-editor/*"} element={<LazyWidgetEditor />} />
          <Route path={"notifications/edit/:templateKey/*"} element={
            <EmailTemplatesContainer>
              <LazyEmailTemplateEditorIndex />
            </EmailTemplatesContainer>
          } />
          {/*
          <Route path={"/form/edit/:formId"} element={<FormDesigner />} />
          */}
        </Route>
      </Routes>
  )
}

export default AppInternal
