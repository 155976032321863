import React, {useCallback, useEffect, useMemo, useState} from 'react';
import BackButton from '../../components/BackButton';
import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {getHttpClient, gqlQuery} from '../../api';
import Spinner from '../../components/form/Spinner';
import Avatar from '../../components/Avatar';
import SettingsSection from '../../components/SettingsSection';
import FormGroup from '../../components/form/FormGroup';
import Input from '../../components/form/Input';
import FileUploaderComponent from '../../components/FileUploaderComponent';
import Select from '../../components/form/Select';
import Button from '../../components/form/Button';
import LazyRangePicker from '../../components/datepickers/LazyRangePicker';
import LoyaltyProgramSelector
  from '../../components/selectors/LoyaltyProgramSelector';
import useObjectState from '../../components/hooks/useObjectState';
import create from 'zustand';
import useToggle from '../../components/hooks/useToggle';
import Modal from '../../components/Modal';
import {FiEye, FiTrash} from 'react-icons/fi';
import Form from '../../components/form/Form';
import {shallowCompareSubset} from '../../func';
import Skeleton from 'react-loading-skeleton';
import {useSettingsStore} from '../../store/settingsStore';
import CopyButton from '../../components/CopyButton';
import {MdOutlineCake, MdPerson} from 'react-icons/md';
import RenderDate from '../../components/RenderDate';
import {FormattedNumber} from 'react-intl';
import useLoyaltyRewards from '../rewards/useLoyaltyRewards';
import RenderReward from '../rewards/components/RenderReward';
import EmptyCard from '../dashboard/components/EmptyCard';
import Pagination from '../../components/table/Pagination';
import TabLink from '../../components/TabLink';
import InputGroup from '../../components/form/InputGroup';
import DefaultCurrencyRender from '../../components/DefaultCurrencyRender';
import ApplyToSelector from '../loyalty/crud/task-components/ApplyToSelector';
import AsyncProductSelector
  from '../../components/product-selector/AsyncProductSelector';
import CurrencyInput from '../../components/form/CurrencyInput';
import Checkbox from '../../components/form/Checkbox';

const useCustomer = create((set, get)=>{
    return {
      customer:null,
      onUnmount:()=>{
        set({customer: null})
      },
      load:(customerId)=>{
        gqlQuery(`
          query ($customerId: Int){
            customerById(customerId: $customerId){
              _id
              accessToken
              acceptsMarketing
              platformCustomerId
              firstName
              lastName
              emailAddress
              referralCode
              profilePhotoUrl
              loyaltyProgramId
              offlineCardNumber
            }
          }
        `,{
          customerId: Number(customerId)
        }).then(({customerById}) => {
          set({customer:customerById})
        })
      },
      update:(customerId, change)=>{
        return getHttpClient().patch('/admin/customers/'+customerId, change)
            .then(()=>{
              return get().load(customerId)
            })
      }
    }
})

function CustomerDetails(){
  const onUnmount = useCustomer(useCallback((state)=>state.onUnmount,[]))
  useEffect(()=>{
    return onUnmount
  })
  return <Routes>
      <Route element={<CustomerDetailsContainer/>}>
        <Route path={"settings"} element={<CustomerSettings />} />
        <Route path={"reviewsAndFaq"} element={<CustomerReviews />} />
        <Route index element={<CustomerDetailsTab />} />
      </Route>
  </Routes>
}
function CustomerSettings(){
  const params = useParams()
  const customerId = params.id;
  const customer = useCustomer(useCallback((state)=>state.customer,[]))
  const httpUpdate = useCustomer(useCallback((state)=>state.update,[]))
  const [data, setData, replaceData] = useObjectState({})
  useEffect(()=>{
    replaceData(customer)
  },[customer, replaceData])
  const [saving, setSaving] = useState(false)
  const onSave = ()=>{
    setSaving(true)
    return httpUpdate(customerId, data)
        .then(()=>{
          setSaving(false)
        })
  }

  const hasChanged = useMemo(()=>{
    return !shallowCompareSubset(data, customer)
  },[customer, data])

  return <>
      <SettingsSection title={"Profile"} subtitle={"Customize profile"}>
        <Form onSubmit={onSave}>
        <FormGroup label={"Profile photo"}>
          <FileUploaderComponent value={data.profilePhotoUrl} onChange={setData('profilePhotoUrl')} />
        </FormGroup>
        <div className="row">
          <div className="col-md-6">
            <FormGroup label={"First name"}>
              <Input value={data.firstName} onChange={setData('firstName')} />
            </FormGroup>
          </div>
          <div className="col-md-6">
            <FormGroup label={"Last name"}>
              <Input value={data.lastName} onChange={setData('lastName')} />
            </FormGroup>
          </div>
        </div>
        <FormGroup label={"Email Address"}>
          <Input value={data.emailAddress} onChange={setData('emailAddress')} />
        </FormGroup>
        <FormGroup label={"Referral code"}>
          <Input value={data.referralCode} onChange={setData('referralCode')}  />
        </FormGroup>
          <FormGroup label={"POS Card Number"}>
            <Input value={data.offlineCardNumber} onChange={setData('offlineCardNumber')}  />
          </FormGroup>
          {
            hasChanged ?
                <Button loading={saving}
                        type={"submit"}
                        onClick={onSave}
                        className="btn btn-primary">Submit</Button> : null
          }
        </Form>
      </SettingsSection>
    <SettingsSection title={"Birthdays or Events"} subtitle={"Birthdays or anniversaries of this customer."}>
      <BirthdaysAndEvents customerId={customerId} />
    </SettingsSection>
    <DeleteCustomer customerId={customerId} />

  </>
}

function DeleteCustomer({customerId}){
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const deleteCustomer = useCallback(()=>{
    if(window.confirm('Are you sure you want to delete this customer account ?')) {
      setLoading(true);
      getHttpClient().delete('/admin/customers/'+customerId)
        .then(({data})=>{
            setLoading(false)
            navigate('/customers',{replace:true})
      })
    }
  },[customerId, navigate])
  return     <SettingsSection title={"Delete customer"} subtitle={"Permanently delete this customer and all of its data from the system"}>
    <Button loading={loading} onClick={deleteCustomer} className="btn btn-danger">Delete customer</Button>
  </SettingsSection>
}

const useBirthday = create((set)=>{
  return {
    birthdays:[],
    load:(customerId)=>{
      return getHttpClient().get('/admin/customers/'+customerId+'/birthdays')
          .then(({data})=>{
            set({birthdays: data.birthdays})
        })
    },
    deleteBirthday:(customerId, birthdayId)=>{
      return getHttpClient().delete('/admin/customers/'+customerId+'/birthdays/'+birthdayId)
          .then(({data})=>{
            set({birthdays: data.birthdays})
          })
    },
    upsertBirthday:(customerId, data)=>{
      return getHttpClient().post('/admin/customers/'+customerId+'/birthdays',{
        birthday: data,
      })
          .then(({data})=>{
            set({birthdays: data.birthdays})
          })
    }
  }
})
function BirthdaysAndEvents({customerId}){
  const [birthdays, load] =useBirthday((state)=>[state.birthdays, state.load])

  useEffect(()=>{
    load(customerId).then(()=>{
      console.log('done')
    })
  }, [customerId, load])


  return <>
    {
      birthdays.map((item)=>{
        return <div key={String(item._id)} className={"bg-light mb-2 p-2 border rounded"}>
          <BirthdayItem customerId={customerId}  birthday={item} />
        </div>
      })
    }
    <AddBirthdayModal customerId={customerId} />
  </>
}

function AddBirthdayModal({customerId}){
  const [visible, toggle] = useToggle()
  const [data, setData] = useObjectState({})
  const save = useBirthday((state)=>state.upsertBirthday)
  const [loading, setLoading] = useState(false)
  const submit = useCallback(()=>{
      setLoading(true)
      save(customerId, data).then(()=>{
        setLoading(false)
        toggle()
      })
  },[customerId, data, save, toggle])
  return  <> <button onClick={toggle} className="btn btn-link">Add birthday</button>
    <Modal
        title={"Add an event"}
        confirmLoading={loading}
        onSubmit={submit}
        visible={visible} onCancel={toggle}>
      <div className="row">
        <div className="col-md-6">
          <FormGroup label={"Event"}>
            <Select value={data.event_type} onChange={setData('event_type')} options={[
              {
                label:"Birthday",
                value:'birth'
              },
              {
                label:"Marriage Anniversary",
                value:'marriage'
              },
            ]} />
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup label={"Person (optional)"} info={'The person for which this event is being celebrated'}>
            <Input value={data.person} onChange={setData('person')}/>
          </FormGroup>
        </div>
      </div>
        <FormGroup label={"Event date"}>
          <Input type={"date"} value={data.date} onChange={setData('date')} />
        </FormGroup>
    </Modal>
  </>
}

function BirthdayItem({customerId, birthday}){
  const [data, setData] = useObjectState(birthday || {})
  const update = useBirthday((state)=>state.upsertBirthday)
  useEffect(()=> {
    setData(birthday || {})
  },[birthday, setData])
  const hasChanged = useMemo(()=>{
    return data ? data.event_type !== birthday.event_type || data.person !== birthday.person || data.date !== birthday.date : false
  },[birthday, data])
  const [saving, setSaving] = useState(false)
  const saveChanges = function(){
    setSaving(true)
    update(customerId, data)
        .then(()=>{
          setSaving(false)
      })
  }
  return  <><div className="row">
    <div className="col-md-6">
      <FormGroup label={"Event"}>
        <Select value={data.event_type} onChange={setData('event_type')} options={[
          {
            label:"Birthday",
            value:'birth'
          },
          {
            label:"Marriage Anniversary",
            value:'marriage'
          },
        ]} />
      </FormGroup>
    </div>
    <div className="col-md-6">
      <FormGroup label={"Person (optional)"} info={'The person for which this event is being celebrated'}>
        <Input value={data.person} onChange={setData('person')}/>
      </FormGroup>
    </div>
  </div>
  <FormGroup label={"Event date"}>
    <Input type={"date"} value={data.date} onChange={setData('date')} />
  </FormGroup>
    <div className="d-flex align-items-center justify-content-between">
      {
        hasChanged ? <Button onClick={saveChanges} loading={saving} className="btn btn-primary">Save</Button> : <span>

      </span>

      }
      <DeleteBtn customerId={customerId} _id={birthday._id} />

    </div>
    </>
}
function DeleteBtn({customerId, _id}){
  const del = useBirthday((state)=>state.deleteBirthday)
  const [deleting, setDeleting] = useState(false)
  function deleteBirthday(){
    if(window.confirm('Delete this event entry ?')){
      setDeleting(true)
      del(customerId, _id).then(r => {
        setDeleting(false)
      })
    }
  }
  return <Button loading={deleting} className="btn text-danger" onClick={deleteBirthday}><FiTrash /></Button>

}

function CustomerFaq(){
  return "Reviews"
}
function CustomerReviews(){

  return <>


  </>
}

const buildReferralLink = function(website, referralCode){
  //TODO make this more robust
  return `https://${website}?ref=${referralCode}`

}

function CustomerDetailsTab(){
  const params = useParams();
  const customer = useCustomer((state)=>state.customer)
  const website = useSettingsStore((state)=>state.settings.website)

  const [saving, setSaving] = useState(false)
  const update = useCustomer((state)=>state.update)
  const changeLoyaltyProgram = useCallback((newProgram)=>{
    setSaving(true)
    update(params.id, {loyaltyProgramId: newProgram})
        .then(()=>{
          setSaving(false)
        })
  },[params.id, update])
  const referralLink = useMemo(()=>{
    return buildReferralLink(website, customer.referralCode)
  }, [website, customer.referralCode])
  return <>
  <div className="card-group">
    <div className="card">
      <div className="card-header">Profile</div>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <div>
            <strong>Referral Link</strong>
          </div>
          <small>{referralLink}</small>
          <div className={"d-flex align-items-center justify-content-between"}>
            <CopyButton className={"px-0 btn btn-link btn-sm"} text={referralLink}>Copy link</CopyButton>
            <a href={referralLink} target={"_blank"} rel={"noopener noreferrer nofollow"} className={"btn btn-link btn-sm"}>Open link</a>
          </div>
        </li>
        <li className="list-group-item">
          <div>
            <strong>Birthday and Events</strong>
            <RenderBirthdays customerId={customer._id} />
          </div>
        </li>
        <li className="list-group-item d-flex align-items-center justify-content-between">
            <strong>Accepts Marketing</strong>
          <span className={`badge ${customer.acceptsMarketing ? 'bg-success' : 'bg-warning'}`}>
            {
              customer.acceptsMarketing ? 'YES' : 'NO'
            }

          </span>
        </li>
      </ul>
    </div>
    <div className="card">
      <div className="card-header">Statistics</div>
      <StatsTable customerId={customer._id} />
    </div>
    <div className="card">
      <div className="card-header">Actions</div>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <FormGroup label={"Change loyalty program"}>
            {
              saving ? <Skeleton height={38}/> :
                  <LoyaltyProgramSelector value={customer.loyaltyProgramId}
                                          onChange={changeLoyaltyProgram}/>

            }
          </FormGroup>
        </li>
        <button onClick={()=>{
          changeLoyaltyProgram(-1)
        }} className="list-group-item list-group-item-action">
          Exclude from loyalty programs
        </button>
      <AddLoyaltyPointsModal customerId={customer._id} />
      <GiveRewardManually customerId={customer._id} />
      <ImpersonateUser accessToken={customer.accessToken} />
      </ul>
    </div>

  </div>
    <h6 className={"mt-4"}>Rewards Activity</h6>
    <div className="mt-2">
      <RenderRewards customerId={customer._id} />
    </div>
    </>

}

function ImpersonateUser({accessToken}){
  const [shopifyLoyaltyPageHandle, website] = useSettingsStore(
      useCallback((state)=>[state.settings.shopifyLoyaltyPageHandle, state.settings.website], []))

  const url = useMemo(()=>{
    if(shopifyLoyaltyPageHandle){
      return  `https://${website}/pages/${shopifyLoyaltyPageHandle}?uxAuthToken=${accessToken}`
    }else{
      return `https://${website}?uxAuthToken=${accessToken}`
    }
  },[accessToken, shopifyLoyaltyPageHandle, website])
  return <a className={"list-group-item list-group-item-action"} href={url} target={"_blank"}>
    View store as user
  </a>
}


function RenderRewards({customerId}) {
  const [data, count] = useLoyaltyRewards((state)=>[state.data, state.count])
  const load = useLoyaltyRewards((state)=>state.load)
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const limit = useMemo(()=>{
    return searchParams.get('limit') || 10
  },[searchParams])
  const page = useMemo(()=>{
    return searchParams.get('page') || 0
  },[searchParams])

  useEffect(()=>{
    setLoading(true)
    load(limit, Number((page * limit)), {
      customerId:{
        value:customerId,
      }
    }).then(()=>{
      setLoading(false)
    })
  },[customerId, limit, load, page])

  return (
      <div>
        {
          data.length > 0 ? <ul className="list-group list-group-flush">
            {
              data.map((item) => {
                return <RenderReward hideCustomer id={item._id} reward={item}/>
              })
            }
          </ul> : <>
            {
              loading ? <Skeleton count={5} height={32} /> : <EmptyCard />
            }
          </>
        }
      <Pagination loading={loading} count={count} limit={limit} />
      </div>
  );
}

function RenderBirthdays({customerId}){
  const birthdays = useBirthday((state)=>state.birthdays)
  const load = useBirthday((state)=>state.load)
  const [loading, setLoading] = useState(false)
  useEffect(()=>{
    setLoading(true)
      load(customerId).then(()=> {
        setLoading(false)
      })
  },[load, customerId])
  return <ul className={"list-group list-group-flush"}>
    {
      loading ? <>
        <Skeleton height={40}/>
      </> : <>{
        birthdays && birthdays.length > 0 ? birthdays.map((item) => {
          return <li className={"list-group-item"}>
            {
              item.person ?
                  <span className={"mb-2 d-flex align-items-center"}>
                    <MdPerson className={"text-muted me-2"}/> {item.person}
                </span> : null
            }

            <span className={"d-flex align-items-center"}>
          <MdOutlineCake className={"text-muted me-2"}/> <RenderDate value={new Date(item.date)}/>
        </span>
          </li>
        }) : <p>No birthdays set</p>
      }</>
    }

  </ul>
}

function GiveRewardManually({onChange, customerId}){
  const [visible, toggle] = useToggle();
  const [loading, setLoading] = useState(false)
  const [data, setData] = useObjectState({
    pointsToDeduct:0,
    discountType:'percentage',
    discountValue:'10',
    applyTo:'entireOrder',
    alsoCreateInStore:true,
  })

  const [advanced, toggleAdvanced] = useToggle(false)
  const onSubmit = useCallback(()=>{
      setLoading(true)
      getHttpClient().post('/admin/loyalty/add_coupon',{
        customerId,
        ...data,
      }).then(()=>{
        setLoading(false)
        toggle()
        window.location.reload()
      })
  },[customerId, data, toggle])

  return (<>

    <button onClick={toggle} className="list-group-item list-group-item-action">
      Add voucher
    </button>
    <Modal confirmLoading={loading} visible={visible} onCancel={toggle} onSubmit={onSubmit} title={"Add voucher"}>
      <FormGroup label={"Points to spend (optional)"} info={"The number of points to deduct for this voucher"}>
        <Input value={data.pointsToDeduct} onChange={setData('pointsToDeduct')} type={"number"} />
      </FormGroup>
      <div className="row">
        <div className="col">
          <FormGroup label={"Discount Type"}>
            <Select options={[
              {
                label:"Percentage Off",
                value:'percentage'
              },
              {
                label:"Amount discount",
                value:'fixed_amount'
              },
              {
                label:"Free Shipping",
                value:'free_shipping'
              },
              {
                label:"Free product",
                value:'free_product'
              },

            ]} onChange={setData('discountType')} value={data.discountType} />
          </FormGroup>
        </div>
        {
          data.discountType && <div className="col">
              {
                ['fixed_amount', 'percentage'].indexOf(data.discountType) > -1 ? <>
                      <FormGroup label={"Discount amount"}>
                        <InputGroup
                            prefix={data.discountType === "fixed_amount" ?
                                <DefaultCurrencyRender/> :
                                null}
                            suffix={data.discountType === "percentage" ? "%" : null}>
                          <Input value={data.discountValue}
                                 onChange={setData('discountValue')}/>
                        </InputGroup>
                      </FormGroup>
                    </> :
                    null
              }
              {
                data.discountType === "free_product" ? <>
                  <FormGroup label={"Product"}>
                    <AsyncProductSelector value={data.freeProduct} onChange={setData('freeProduct')} />
                  </FormGroup>
                </> : null
              }
            </div>
        }
      </div>




      <div className="text-end">
        <button type={"button"} onClick={toggleAdvanced} className="btn btn-link btn-sm">Advanced Configuration</button>
    </div>
      {
        advanced ? <>
          {
            ['fixed_amount', 'percentage'].indexOf(data.discountType) > -1 ? <>
              <ApplyToSelector onChange={setData} applyTo={data.applyTo}
                               selectedCollections={data.selectedCollections}
                               selectedProducts={data.selectedProducts}/>
            </> : null
          }
          {
              data.discountType && <>
                <FormGroup label={"Minimum Order value (optional)"}>
                  <CurrencyInput value={data.discountMinOrderValue}
                                 onChange={setData('discountMinOrderValue')}/>
                </FormGroup>
              </>
          }
          {
            data.alsoCreateInStore && <>
              <FormGroup label={"Coupon code prefix"}>
                <Input value={data.discountCodePrefix}
                       onChange={setData('discountCodePrefix')}/>
              </FormGroup>
            </>
          }
          <FormGroup label={"Admin note"} info={"For your reference only"}>
            <Input value={data.adminNote} onChange={setData('adminNote')} />
          </FormGroup>
        </> : null
      }
      <Checkbox label={"Also create in store"} onChange={setData('alsoCreateInStore')} value={data.alsoCreateInStore} />
      {
        !data.alsoCreateInStore ? <>
          <FormGroup label={"Coupon code"} info={"Coupon code that you created in the store"}>
            <Input value={data.coupon}
                   onChange={setData('coupon')}/>
          </FormGroup>
        </>: null
      }

    </Modal>
    </>)
}

function AddLoyaltyPointsModal({onChange, customerId}){
  const [visible, toggle] = useToggle();
  const [data, setData] = useObjectState({
    points:0
  })
  const [loading, setLoading] = useState(false)
  const onSubmit = ()=>{
    const {points, adminNote, customerNote} = data
    setLoading(true)
    getHttpClient().post('/admin/loyalty/add_points',{
      customerId,
      points, adminNote, customerNote
    }).then(()=>{
      setLoading(false)
      toggle()
      window.location.reload()
    })
  }
  return <>
    <button onClick={toggle} className="list-group-item list-group-item-action">
      Add loyalty points
    </button>
    <Modal confirmLoading={loading} visible={visible} onCancel={toggle} onSubmit={onSubmit} title={"Add points"}>
        <FormGroup label={"Points to add"} info={"Tip: You can add a negative number to deduct points"}>
          <Input value={data.points} onChange={setData('points')} type={"number"} />
        </FormGroup>
        <FormGroup label={"Admin note"} info={"For your reference only"}>
          <Input value={data.adminNote} onChange={setData('adminNote')} />
        </FormGroup>
        <FormGroup label={"Customer message"} info={"Message for your customer"}>
          <Input value={data.customerNote} onChange={setData('customerNote')} />
        </FormGroup>
    </Modal>

  </>
}


function StatsTable({customerId}){
  const [dates, setDates] = useState(null)
  const [data, setData] = useState(null)

  const [loading, setLoading] = useState(false)
  useEffect(()=>{
    setLoading(true)
    getHttpClient().get('/admin/customers/'+customerId+'/basic_stats')
        .then(({data})=>{
          setData(data)
          setLoading(false)
        })
  },[customerId])

  const vouchers = useMemo(()=>{
    const p = data ? data.loyalty.find(({type})=>type === "coupon_code") : null;
    return p ? p.count : 0;
  },[data])


  if(!data && !loading) return null;
  return <>
    <div className={"p-2 d-none"}>
     <LazyRangePicker onChange={setDates} value={dates}/>
    </div>
    {
      loading ? <Skeleton count={6} height={32} /> :
    <ul className="list-group list-group-flush">
      <ListItem title={"Orders"}>
        <FormattedNumber value={data.orders.count} />
      </ListItem>
      <ListItem title={"Vouchers"}>
        <FormattedNumber value={vouchers} />
      </ListItem>
      <ListItem title={"Points"}>
        <div className={"text-end"}>
          <span title={"Net points balance"}>
            <FormattedNumber value={data.pointsBalance} />
          </span>
          {
            data.pointsBalance !== data.pointsEarned ?
                <div>
                  <small className="text-success" title={"Points earned by the customer"}>
                    <FormattedNumber value={data.pointsEarned}/>
                  </small>
                  <span className={"mx-1"}>-</span>
                  <small className="text-danger"  title={"Points spent by the customer"}>
                    <FormattedNumber value={data.pointsSpent}/>
                  </small>
                </div> : null
          }

        </div>

      </ListItem>
      <ListItem title={"Total Reviews"}>
        <FormattedNumber value={data.reviews.count} />
      </ListItem>
      <ListItem title={"Total Questions"}>
        <FormattedNumber value={data.faq.count} />
      </ListItem>

    </ul>
    }
  </>
}

function ListItem({title, children}){
  return <li className="list-group-item d-flex align-items-center justify-content-between">
    <span>
      {title}
    </span>
    {children}
  </li>
}

function ViewCustomerInStoreLink({platformCustomerId}){
  const [platform, website] = useSettingsStore((state)=>[state.settings.platform, state.settings.website])
  if(!platformCustomerId) return null;
  if(platform === "shopify"){
    return <small><a rel={"noopener noreferrer nofollow"} href={`https://${website}/admin/customers/${platformCustomerId}`} target={"_blank"} className={"text-decoration-none text-muted"}>View in Shopify admin</a></small>
  }else{
    return null;
  }
}

function CustomerDetailsContainer(props) {
  const params = useParams()
  const [data, load] = useCustomer((state)=>[state.customer, state.load])
  useEffect(()=>{
      load(params.id)
  },[load, params])
  if(!data) return <Spinner />
  return (
      <>
        <BackButton to={"/customers"}  />
        <div className={"d-flex mb-2"}>
          <Avatar size={48} src={data.profilePhotoUrl} name={data.firstName + ' ' +data.lastName} />
          <div className={"ms-2"}>
            <h3 className={"mb-0"}>{data.firstName} {data.lastName}</h3>
            <small>{data.emailAddress}</small>
            <div><ViewCustomerInStoreLink platformCustomerId={data.platformCustomerId} /></div>
          </div>

        </div>
        <ul className="nav nav-tabs mb-2">
          <TabLink to={""} end>Details</TabLink>
          <TabLink to={"settings"}>Settings</TabLink>
        </ul>
        <Outlet />
      </>
  );
}


export default CustomerDetails;
