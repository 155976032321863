import create from 'zustand';
import {getHttpClient} from '../../api';
import produce from 'immer';
import {reorder} from '../../utils/func';

const useLoyaltyPrograms = create((set, get)=>{
  return {
    programs: null,
    loading: false,
    setProgramSettings:(id, change)=>{
      set(produce(state=>{
        let index = state.programs.findIndex((item)=>{
          return Number(item._id) === Number(id)
        });
        state.programs[index].settings = {...state.programs[index].settings, ...change}
      }))
    },
    load: ()=>{
      set({loading:true})
      return getHttpClient().get('/admin/loyalty/programs')
      .then(({data})=>{
        set({
          programs: data.programs,
          loading:false
        })
      })
    },
    create:(name)=>{
      return getHttpClient().post('/admin/loyalty/programs',{
        name
      }).then(({data})=>{
         return get().load().then(()=>{
           return data;
         })
      })
    },
    update:(id, {name, tasks, rewards})=>{
      return getHttpClient().patch('/admin/loyalty/programs/'+id,{
        name, tasks, rewards
      }).then(()=>{
        return get().load()
      })
    },
    httpDeleteProgram:(id, moveCustomersTo)=>{
      return getHttpClient().delete('/admin/loyalty/programs/'+id).then(()=>{
        return get().load()
      })
    },
    httpUpsertTask:(id, task)=>{
      return getHttpClient().put('/admin/loyalty/programs/'+id+"/tasks", {
        task
      }).then(({data})=>{
        return get().load()
      })
    },
    httpDeleteTask:(id, taskId)=>{
      return getHttpClient().delete('/admin/loyalty/programs/'+id+"/tasks/"+taskId).then(({data})=>{
        return get().load()
      })
    },
    httpUpsertReward:(id, reward)=>{
      return getHttpClient().put('/admin/loyalty/programs/'+id+"/rewards", {
          reward
      }).then(({data})=>{
        return get().load()
      })
    },
    httpDeleteReward:(id, rewardId)=>{
      return getHttpClient().delete('/admin/loyalty/programs/'+id+"/rewards/"+rewardId).then(({data})=>{
        return get().load()
      })
    },

    reorderTasks:(programId, fromIndex, toIndex)=>{
      set({
        programs:get().programs.map((item)=>{
          return item._id == programId ? {
            ...item,
            tasks: reorder(item.tasks, fromIndex, toIndex)
          } : item
        })
      })

      return getHttpClient().post(`/admin/loyalty/programs/${programId}/tasks/reorder`,{
        fromIndex, toIndex
      }).then(({data})=>{
        set({
          programs: get().programs.map((item)=>item._id == programId ? data : item)
        })
      })
    },
    reorderRewards:(programId, fromIndex, toIndex)=>{
      set({
        programs:get().programs.map((item)=>{
          return item._id == programId ? {
            ...item,
            rewards: reorder(item.rewards, fromIndex, toIndex)
          } : item
        })
      })
      return getHttpClient().post(`/admin/loyalty/programs/${programId}/rewards/reorder`,{
        fromIndex, toIndex
      }).then(({data})=>{
        set({
          programs: get().programs.map((item)=>item._id == programId ? data : item)
        })
      })
    }
  }
})

export default useLoyaltyPrograms;
