import React, {useCallback, useEffect, useMemo, useState} from 'react';
import BackButton from '../../../components/BackButton';
import {useSearchParams} from 'react-router-dom';
import Select from '../../../components/form/Select';
import FormGroup from '../../../components/form/FormGroup';
import {getHttpClient} from '../../../api';
import Skeleton from 'react-loading-skeleton'
import Button from '../../../components/form/Button';
import Input from '../../../components/form/Input';
import {localesOptions} from '../../../supportedLanguages';

function LocaleEditor(props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [locale, setLocale] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [hasChanged, setHasChanged] = useState(false)
  useEffect(()=>{
    setLocale(searchParams.get('locale'))
  },[searchParams])
  useEffect(()=>{
    if(locale) {
      setData(null)
      setLoading(true)
      getHttpClient().get('/admin/sites/locale_dictionary/' + locale)
          .then(({data})=>{
            setHasChanged(false)
            setData(data.locale)
            setLoading(false)
          })
    }
  },[locale])

  const changed = useCallback((key)=>{
    return (value)=>{
      setData((d)=>{return {...d, [key]:value}})
      setHasChanged(true)
    }
  },[])
  const [saving,setSaving] = useState(false)
  function save(e){
    if(e){
      e.preventDefault()
      e.stopPropagation();
    }
    setSaving(true)
    getHttpClient().post('/admin/sites/locale_dictionary/'+locale,{
      dictionary: data,
    }).then(({data})=>{
      setHasChanged(false)
      setData(data.locale)
      setSaving(false)
    })
  }
  const [filter, setFilter] = useState('')

  const filteredKeys = useMemo(()=>{
    if(!data) return null;
    const f = filter ? filter.toLowerCase() : null
    return f ? Object.keys(data).filter((item)=>item.toLowerCase().includes(f) || data[item].toLowerCase().includes(f)) : Object.keys(data)
  },[data, filter])


  return (
      <>
        <BackButton />
        <form onSubmit={save}>
        <div className="d-flex align-items-center justify-content-between">
          <h2>Translations</h2>
          <Button disabled={!hasChanged} loading={saving} className={"btn btn-primary"}>Submit</Button>
        </div>
        <FormGroup label={"Choose the language"}>
          <Select hasEmpty options={localesOptions} value={locale} onChange={(locale)=>{
            setSearchParams({locale})
          }} />
        </FormGroup>
        {
          loading ? <Skeleton count={10} /> : <>
            {
              data ? <>
                <FormGroup label={"Filter"}>
                  <Input type={'search'} placeholder={'Find a text'} value={filter} onChange={setFilter} />
                </FormGroup>{
                  filteredKeys ? filteredKeys.map((item)=>{
                  return <FormGroup label={item}>
                    <Input value={data[item]} onChange={changed(item)} />
                  </FormGroup>
                }) : null
              } </>
                  : null
            }
          </>
        }
        </form>
      </>
  );
}

export default LocaleEditor;
