import React, {useEffect, useMemo, useState} from 'react';
import Input from '../../components/form/Input';
import {useDebounce} from '../../components/hooks/useDebounce';
import FormGroup from '../../components/form/FormGroup';
import Select from '../../components/form/Select';
import Pagination from '../../components/table/Pagination';
import {gqlQuery} from '../../api';
import {Link, useSearchParams} from 'react-router-dom';
import EmptyCard from '../dashboard/components/EmptyCard';
import Avatar from '../../components/Avatar';
import {FormattedMessage} from 'react-intl';
import Skeleton from 'react-loading-skeleton'
function ListOfCustomers({forceReload}) {
  const [keyword, setKeyword] = useState('')
  const [sort, setSort] = useState('createdAt_desc')
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(100)
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(false)
  const _search = useDebounce(keyword, 200)
  const [searchParams] = useSearchParams()

  const page = useMemo(()=>{
    return Number(searchParams.get('page') || 0)
  },[searchParams])
  useEffect(()=>{
    // load customers
    setLoading(true)
    gqlQuery(`
      query($limit: Int, $offset: Int, $sortBy: CustomerSort $search: String, $programId: String){
       customers(limit: $limit, offset: $offset, programId: $programId, search: $search
       sortBy: $sortBy
       ){
        data {
           _id
           name
           profilePhotoUrl
           emailAddress
           totalPoints
           totalVouchers
           totalReviews
           totalFaq
           totalOrders
           totalReferrals
         }
        count
        limit
        offset
      }
    }
    `,{
      limit,
      offset:Math.round(page * limit),
      search:_search,
      sortBy: sort,
    }).then(({customers})=>{
      setLoading(false)
      setCount(customers.count)
      setCustomers(customers.data)
    })
  },[page,forceReload, _search, sort, limit])
  return (
      <>
        <div className="bg-light border rounded border-start border-end p-2">
          <div className="row">
            <div className="col-md-6">
              <FormGroup label={"Search"}>
                <Input placeholder={"Search..."} type={"search"} value={keyword} onChange={setKeyword} />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup label={"Sort by"}>
                <Select value={sort} options={[
                  {
                    label:'Newest',
                    value:'createdAt_desc'
                  },
                  {
                    label:'Oldest',
                    value:'createdAt_asc'
                  },

                  {
                    label:'Name A-Z',
                    value:'name_asc'
                  },

                  {
                    label:'Name Z-A',
                    value:'name_desc'
                  },
                  {
                    label:'Most Points',
                    value:'points_desc'
                  },
                  {
                    label:'Most Referrals',
                    value:'referrals_desc'
                  },
                ]} onChange={setSort} />
              </FormGroup>
            </div>
          </div>
        </div>
        {
          loading ? <><Skeleton height={32} count={5} /></> : customers.length > 0 ? <ul className={'list-group my-2'}>{
            customers.map((item)=>{
              return <RenderCustomer key={String(item._id)} customer={item} />
            })
          }</ul> : <div className="my-2 rounded border overflow-hidden"><EmptyCard /></div>
        }
        <Pagination count={count}
                    limit={limit} onLimitChange={setLimit}
        />
      </>
  );
}

function RenderCustomer({customer}){
  return <Link to={""+customer._id} className={'list-group-item list-group-item-action'}>
      <div className="d-flex align-items-start">
          <div className="mt-1">
            <Avatar name={customer.name} src={customer.profilePhotoUrl} size={32} />
          </div>
          <div className={"ms-2"}>
            <span>{customer.name}</span>
            <small className={"text-muted d-block"}>{customer.emailAddress}</small>
            <span className={"d-flex gap-2 mt-2"}>
              <ItemBadge id={"numReviews"} x={customer.totalReviews} />
              <ItemBadge id={"numFaqs"} x={customer.totalFaq} />
              <ItemBadge id={"numPoints"} x={customer.totalPoints} />
              <ItemBadge id={"numVouchers"} x={customer.totalVouchers} />
            </span>
          </div>
      </div>
  </Link>
}

function ItemBadge({id, x}){
  return <span className="badge border text-secondary">
                <FormattedMessage id={id} values={{x}} />
        </span>
}



export default ListOfCustomers;
