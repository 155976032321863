import React from 'react';

function InputGroup({prefix, suffix, children}) {
  return (
      <div className="input-group">
        {
          prefix &&
          <span className="input-group-text">{prefix}</span>
        }
        {children}
        {
          suffix &&
          <span className="input-group-text">{suffix}</span>
        }
      </div>

);
}

export default InputGroup;
