import {
  FiCreditCard,
  FiGift,
  FiHelpCircle,
  FiInbox,
  FiInstagram,
  FiMessageSquare,
  FiMinimize2,
  FiPercent,
  FiShare,
  FiShare2,
  FiShoppingCart,
  FiStar,
  FiTruck,
  FiUser,
} from 'react-icons/fi';
import {MdOutlineCake} from 'react-icons/md';
import {randomString} from '../../../utils/func';

export const taskIcons = {
  place_an_order: <FiShoppingCart/>,
  refer_a_friend: <FiShare />,
  birthday:<MdOutlineCake />,
  social_share: <FiShare2 />,
  signup: <FiUser />,
  social_follow: <FiInstagram />,
  custom_task:<FiStar />,
  post_a_review:<FiMessageSquare />,
  post_a_faq: <FiHelpCircle />,
  redeem_points:<FiMinimize2 />,
  accepts_marketing:<FiInbox />
}
export const rewardIcons = {
  free_shipping: <FiTruck />,
  percentage: <FiPercent />,
  fixed_amount: <FiCreditCard />,
  increment_amount:<FiCreditCard />,
  free_product: <FiGift />
}


function createDefaultNewTask({type}){

  return {
    "task": {
      "id": randomString(),
      "type": type,
      "friendReward": ""
    },
    "rewards": [
      {
        "type": "give_points",
        "giveAs": "in_wallet",
        "availability": "immediate",
        "expiration": "never",
        "pointsType": "fixed"
      }
    ]
  }

}

/*
export const availableTasks = [
  {
    label:'Place an order',
    type:'placeAnOrder',
    description:'Customers are rewarded when they place an order'
  },
  {
    label:'Refer a customer',
    type:'referACustomer',
    description:'Customers are rewarded when they send other customers to your store with their friends'
  },
  {
    label:'Refer a customer',
    type:'referACustomer',
    description:'Customers are rewarded when they send other customers to your store with their friends'
  },
  {
    label:'Birthday reward',
    type:'birthday',
    description:'Customers are rewarded when their birthday comes'
  },
  {
    label:'Visit store',
    type:'visit',
    description:'Customers are rewarded when they visit the store'
  },
  {
    label:'Sign up',
    type:'signUp',
    description:'Customers are rewarded when they sign up for an account'
  },
  {
    label:'Social share',
    type:'socialShare',
    description:'Customers are rewarded when they share your content on social media or their blog or website'
  },
  {
    label:'Custom Task',
    type:'customTask',
    description:'Customers are rewarded for when they complete your defined task'
  },
  {
    label:'Consistent Shopper',
    type:'consistentShopper',
    description:'Customers are rewarded for being a loyal shopper'
  },
]


 */
