import React, {useState} from 'react';
import {getHttpClient} from '../api';
import {support_email} from '../config';
import FormGroup from '../components/form/FormGroup';
import TextArea from '../components/form/TextArea';
import Button from '../components/form/Button';
import Form from '../components/form/Form';
import {FiBook, FiGlobe, FiYoutube} from 'react-icons/fi';

function ContactIndex() {
  const [loading, setLoading] = useState(false)
  const[result, setResult] = useState(null)
  const [message, setMessage] = useState(new URL(document.location.href).searchParams.get('body'))
  const onSubmit = (e)=>{
    setLoading(true)
    getHttpClient().post('/admin/contact',{
      message
    }).then(({data})=>{
      setResult(data)
      setMessage('')
      setLoading(false)
    }).catch((e)=>{
      setLoading(false)
    })
  }

    return (
        <>
          <h2>Contact us</h2>
          <div className="row gy-3">
            <div className="col-md-6">
              <p>Have a query, suggestion, feedback? Need custom feature built into the app? Facing issues with app setup ?</p>
              <p>Send us an email at <a href={`mailto:${support_email}`} target={"_blank"}>{support_email}</a>. Our support team is available 24x7. Or you can use the contact form below. You can also connect with us on <a target={"_blank"} href={"https://facebook.com/goaffpro/"}>Facebook</a> and <a target={"_blank"} href={"https://twitter.com/goaffpro"}>Twitter</a></p>
              {
                  result && <p className="alert alert-success">
                    {`We have received your message. Your support ticket no. is #${result.ticket}. A message acknowledgment is also sent to your email address ${result.adminEmail}`}
                  </p>
              }
              <Form onSubmit={onSubmit}>
                <FormGroup label={"Your message"}>
                  <TextArea value={message} placeholder={"Your message"} rows={5} onChange={setMessage} />
                </FormGroup>
                  <Button loading={loading} disabled={!message} className="btn btn-primary" onClick={onSubmit}>
                    Send Message
                  </Button>
              </Form>
            </div>
            <div className="col-md-6 d-none">
              <h6>Helpful Resources</h6>
              <ul className="list-group">
                <ResourceLink href={"https://docs.goaffpro.com"}
                              title={"Knowledge base"}
                              Icon={FiBook}
                >
                </ResourceLink>
                <ResourceLink href={"https://www.youtube.com/channel/UCo95DNTq0v3WDjqECo3eKWQ"}
                              title={"YouTube Channel"}
                              Icon={FiYoutube}
                >
                </ResourceLink>
                <ResourceLink href={"https://blog.goaffpro.com"}
                              Icon={FiGlobe}
                              title={"Blog"}>
                </ResourceLink>
              </ul>
            </div>
          </div>
        </>
    );
}

function ResourceLink({Icon, href, title}){
  return <a href={href}
            target={'_blank'}
            className={"list-group-item py-3 list-group-item-action d-flex align-items-center"}>
    <Icon />
    <span className={"ms-3"}/>
    {title}
  </a>
}

export default ContactIndex;
