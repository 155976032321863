import React, {useCallback} from 'react';

function Form({onSubmit, className, children}) {
  const submit = useCallback((e)=>{
    if(e){
      e.preventDefault();
      e.stopPropagation();
    }
    onSubmit(e)
  },[onSubmit])
  return (
      <form onSubmit={submit} className={className}>
        {children}
      </form>
  );
}

export default Form;
