import React, {useCallback, useEffect, useState} from 'react';
import FormGroup from '../../components/form/FormGroup';
import Select from '../../components/form/Select';
import AsyncProductSelector
  from '../../components/product-selector/AsyncProductSelector';
import Input from '../../components/form/Input';
import AsyncCustomerSelector
  from '../../components/customer-selector/AsyncCustomerSelector';
import Pagination from '../../components/table/Pagination';
import {FormattedDate} from 'react-intl';
import useToggle from '../../components/hooks/useToggle';
import Modal from '../../components/Modal';
import {useSearchParams} from 'react-router-dom';
import {useDebounce} from '../../components/hooks/useDebounce';
import {getHttpClient, gqlQuery} from '../../api';
import useObjectState from '../../components/hooks/useObjectState';
import TextArea from '../../components/form/TextArea';
import Button from '../../components/form/Button';
import Skeleton from 'react-loading-skeleton';
import {useForceReload} from '../../components/hooks/hooks';
import RenderCustomer from '../reviews/components/RenderCustomer';
import {FiHeart, FiTrash} from 'react-icons/fi';
import EmptyCard from '../dashboard/components/EmptyCard';
import create from 'zustand';

const useFaq = create((set, get)=>{
  return {
    faqs: [],
    count: 0,
    loading: false,
    load: (limit, page, filters) => {
      set({loading: true})
      return gqlQuery(`query ($limit: Int, $offset:Int, $filter: FaqFilter){
      faqs(limit: $limit, offset:$offset, filter: $filter){
        data {
          _id
          question
          answer
          status
          answeredAt
          createdAt
          isFeatured
          customer {
            _id
            firstName
            lastName
            emailAddress
          }
          product {
            name
          }
        }
        count
        limit
        offset
    }
    }`, {
        limit, offset: limit * page,
        filter: {
          customerId: filters.customer ? Number(filters.customer.value) : null,
          productId: filters.product ? filters.product.value : null,
          search: filters.search || null,
          status: filters.status || null,
          sortBy: filters.sortBy || null
        }
      }).then(({faqs}) => {
        set({
          faqs: faqs.data,
          count: faqs.count,
          loading: false
        })
      })
    },
    del:(faqId)=>{
        return getHttpClient().delete('/admin/faq/' + faqId)
            .then(() => {
              set({faqs:get().faqs.filter((i)=>i._id !== faqId)})
      })
    },
    updateFaq:(faqId, {answer, isFeatured, status})=>{
      return getHttpClient().patch('/admin/faq/'+faqId,{
        answer,
        status,
        isFeatured
      }).then(({data})=>{
        set({faqs: get().faqs.map((i)=>{
          return i._id === faqId ? {...i,...data} : i
      })})
      })
    }
  }
})

const reducer = (state)=>[state.count, state.faqs, state.load, state.loading]
function FaqIndex(props) {
  const [count, data, load, loading] = useFaq(reducer)
  const [searchParams, setSearchParams] = useSearchParams()
  const [forceReload, doReload] = useForceReload()
  const [filters, setFilters] = useObjectState({})
  const limit = Number(searchParams.get('limit') || 10);
  const page = searchParams.get('page')
  useEffect(()=>{
    load(limit, page, filters)
        .then(()=>{

      })
  },[forceReload, limit, page, filters, load])

  return (
      <>
        <div className="row mb-2">
          <div className="col-md-6">
            <h2>FAQ</h2>
            <div className={"text-muted"}>Allow your customers to ask questions on your products.</div>
          </div>
          <div className="col-md-6">
             <AddAQuestionModal onChange={doReload} />
          </div>
        </div>
        <FAQFilters filters={filters} setFilters={setFilters} />
        {
          loading ? <Skeleton count={limit} height={40} /> :  <ul className="list-group mt-4">
            {
              data.length > 0 ? data.map((item)=>{
                return <FAQItem onChange={doReload} key={String(item._id)} faq={item} />
              }) : <EmptyCard />
            }
          </ul>
        }

        {
          count > 0 ?
              <div className="my-2"><Pagination limit={limit} count={count}/></div> : null
        }

      </>
  );
}

function FAQFilters({filters, setFilters}){
  const [search, setSearch] = useState(filters.search)
  const _search = useDebounce(search, 300)
  useEffect(()=>{
    setFilters('search')(_search)
  },[setFilters, _search])

  useEffect(()=>{
    setSearch(filters.search)
  },[filters.search])
  return <div className="bg-light rounded border p-3">
    <div className="row">
      <div className="col-md-4">
        <FormGroup label={"Filter by customer"}>
          <AsyncCustomerSelector value={filters.customer} onChange={setFilters('customer')} />
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup label={"Search"}>
          <Input value={search} placeholder={"Search..."} onChange={setSearch} />
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup label={"Filter by product"}>
          <AsyncProductSelector value={filters.product} onChange={setFilters('product')} />
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup label={"Filters by status"}>
          <Select hasEmpty value={filters.status} onChange={setFilters('status')} options={[
            {
              label:'Unanswered',
              value:'unanswered'
            },
            {
              label:'Answered',
              value:'answered'
            },
            {
              label:'Unpublished',
              value:'unpublished'
            },
            {
              label:'Published',
              value:'published'
            }
          ]} />
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup label={"Sort by"}>
          <Select value={filters.sortBy} onChange={setFilters('sortBy')} hasEmpty options={[
            {
              label:'Most recent first',
              value:'createdAtDesc'
            },
            {
              label:'Oldest first',
              value:'createdAtAsc'
            },
            {
              label:'Recently Answered',
              value:'answeredAtDesc'
            },
            {
              label:'Recently Published',
              value:'publishedAtDesc'
            },
          ]} />
        </FormGroup>
      </div>
    </div>

  </div>
}

function AddAQuestionModal({onChange}){
  const [visible, toggle] = useToggle(false)
  const [data, setData] = useObjectState({
    question:''
  })
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  function submit(){
    const {question, answer, customer, product} = data;
    setLoading(true)
    getHttpClient().post('/admin/faq',{
      question,answer,
      customerId: customer ? customer.value : 0,
      productId: product?.value
    }).then(({data})=>{
      setLoading(false)
      toggle()
      onChange()
    }).catch(()=>{
      setLoading(false)
      setError(true)
    })
  }
  const isDisabled = !data || !data.question || !data.answer || !data.product

  return (
      <>
        <button onClick={toggle} className={"btn btn-link px-0"}>Add a question</button>
        <Modal confirmLoading={loading}
               disabled={isDisabled}
               onSubmit={submit} visible={visible} onCancel={toggle} title={"Ask a question"}>
          <FormGroup label={"Your question"}>
            <Input value={data.question} onChange={setData('question')} />
          </FormGroup>
          <FormGroup label={"Your answer"}>
            <TextArea value={data.answer} onChange={setData('answer')} />
          </FormGroup>
          <FormGroup label={"Asked by (optional)"}>
            <AsyncCustomerSelector value={data.customer} onChange={setData('customer')} />
          </FormGroup>
          <FormGroup label={"Product"}>
            <AsyncProductSelector value={data.product} onChange={setData('product')} />
          </FormGroup>
        </Modal>
      </>
  )
}

function DeleteBtn({faqId}){
  const [deleting, setDeleting] = useState(false)
  const deleteFaq = useFaq(useCallback((state)=>state.del,[]))
  function del(){
    if(window.confirm('Delete this question ?')) {
      setDeleting(true)
      deleteFaq(faqId)
    }
  }
  return <Button loading={deleting} onClick={del} className="btn btn-link text-danger"><FiTrash /></Button>
}

function FAQItem({faq, onChange}){
  const {customer, product} = faq;
  const [editMode, toggle] = useToggle(false)
  const [answer, setAnswer] = useState('')
  useEffect(()=>{
    setAnswer(faq.answer)
  },[faq.answer])
  return <li className="list-group-item">
    <div className="row mb-2">
      <div className="col">
        <div className={"pre-line"}>{faq.question}</div>
        <small className={"text-muted"}>by <RenderCustomer customer={faq.customer} /></small>
        <div className={"text-wrap"}>
          <small className={"text-muted"}>on {faq.product ? faq.product.name : 'Store'}</small>
        </div>
      </div>
      <div className="col-auto text-end">
        <div className={"text-end"}><small><FormattedDate month={"short"} day={"2-digit"} year={"numeric"} value={new Date(faq.createdAt)} /></small></div>
        <RenderStatus status={faq.status} />
      </div>
    </div>
    {
      editMode ? <ReplyForm onToggle={toggle} onChange={onChange} faqId={faq._id} faqAnswer={faq.answer} /> : null
    }
    {
      !editMode ? <>
        {
          faq.answer &&
          <div className="bg-light border p-2 rounded ms-2 pre-line">
            {faq.answer}
          </div>
        }
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <RenderFeatured _id={faq._id} isFeatured={faq.isFeatured} />
            <button className="btn btn-link" onClick={toggle}>{ faq.answer ? "Update answer" : "Reply"}</button>
            <PublishButton onChange={onChange} faqId={faq._id} status={faq.status} />
          </div>
          <DeleteBtn onChange={onChange} faqId={faq._id} />
        </div>
      </> : null
    }
  </li>
}

function RenderFeatured({_id, isFeatured}){
  const [f, setF] = useState(false)
  const updateFaq = useFaq((state)=>state.updateFaq)
  useEffect(()=>{
    setF(isFeatured)
  },[isFeatured])
  const toggle = useCallback(()=>{
    setF(!f)
    updateFaq(_id, {isFeatured: f ? 0 : 1}).then(r => {
    })
  },[_id, f, updateFaq])
  if(f){
    return <button
        title={"Mark question as not important"}
        onClick={toggle} className={"btn"}><FiHeart style={{color:'#e84393',fill:"#e84393"}} /></button>
  }else{
    return <button
        title={"Mark question as featured"}
        onClick={toggle} className={"btn"}><FiHeart  /></button>
  }
}

function PublishButton({status, faqId}){
  const [saving, setSaving] = useState(false)
  const updateFaq = useFaq((state)=>state.updateFaq)
  const unpublish = useCallback(()=>{
    setSaving(true)
    updateFaq(faqId, {status:'pending'}).then(r => {
      setSaving(false)
    })
  },[updateFaq, faqId])

  const publish = useCallback(()=>{
    setSaving(true)
    updateFaq(faqId, {status:'approved'}).then(r => {
      setSaving(false)
    })
  },[updateFaq, faqId])
  if(status === "pending"){
    return <Button loading={saving} onClick={publish} className="btn btn-link text-success">Publish</Button>
    }else{
    return <Button loading={saving} onClick={unpublish} className="btn btn-link text-warning">Un-Publish</Button>
  }
}

function ReplyForm({onToggle, faqId, faqAnswer}){
  const [answer, setAnswer] = useState(faqAnswer)
  const [saving, setSaving] = useState(false)
  const updateFaq = useFaq(useCallback((state)=>state.updateFaq,[]))

  useEffect(()=>{
    setAnswer(faqAnswer)
  },[faqAnswer])
  const onSubmit = useCallback((e)=>{
    if(e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setSaving(true)
    updateFaq(faqId, {answer}).then(()=>{
      setSaving(false)
      onToggle()
    })
  },[answer, faqId, updateFaq])

  return <div className={"bg-light p-2"}><FormGroup>
    <TextArea value={answer} onChange={setAnswer} />
  </FormGroup>
    <div className="d-flex gap-2">
      <Button loading={saving} onClick={onSubmit} className="btn btn-primary">Submit</Button>
      <button onClick={onToggle} className="btn">Cancel</button>
    </div>
    </div>
}


function RenderStatus({status}){
  return <small className={`badge ${status==="pending" ? 'bg-warning':'bg-success'}`}>{status}</small>
}

export default FaqIndex;
