export const langmap = {
  "en": "English",
  "fr": "French",
  it: "Italian",
  "de": "German",
  el: "Greek",
  he: "Hebrew",
  hu: 'Hungarian',
  tr: 'Turkish',
  "no": "Norwegian",
  "pt": "Portuguese",
  "pl": "Polish",
  "ru": "Russian",
  "es": "Spanish",
  "sv": "Swedish",
  "vi": "Vietnamese",
  "ar": "Arabic",
  "nl": "Dutch",
  "ja": "Japanese",
  "zh": "Chinese (Simplified)",
  "zh-TW": "Chinese (Traditional)",
  "ko": "Korean",
  "fi": "Finnish",
  'cs': 'Czech',
  'da': 'Danish',
  'ro': 'Romanian',
  'th': 'Thai',
  'lt': 'Lithuanian',
  'ms': 'Malay',
}

export const localesOptions = Object.keys(langmap).map((key)=>{
  return {
    label:langmap[key],
    value:key,
  }
})
