import React, {useEffect, useState} from 'react';
import {gqlQuery} from '../../../api';
import Skeleton from 'react-loading-skeleton';
import EmptyCard from './EmptyCard';
import RenderCustomer from '../../reviews/components/RenderCustomer';
import {Link} from 'react-router-dom';
import {FormattedDate} from 'react-intl';

function RecentFaqs(props) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  useEffect(()=>{
    setLoading(true)
    gqlQuery(`query ($limit: Int){
      faqs(limit: $limit){
        data {
          _id
          question
          answer
          status
          answeredAt
          createdAt
          customer {
            _id
            firstName
            lastName
            emailAddress
          }
          product {
            name
          }
        }
      }
    }`, {limit:5}).then(({faqs})=>{
      setData(faqs.data)
      setLoading(false)
    })
  },[])

  return (
      <>
        {
          loading ? <Skeleton count={5} height={36} /> : <ul className={'list-group list-group-flush'}>
            {
              data && data.length > 0 ? data.map((faq)=>{
                return <Link key={String(faq._id)} to={"/faq#"+faq._id} className="list-group-item list-group-item-action">
                  <div className="row mb-2">
                    <div className="col">
                      <div>{faq.question}</div>
                      <small className={"text-muted"}>by <RenderCustomer customer={faq.customer} /></small>
                      <div className={"text-wrap"}>
                        <small className={"text-muted"}>on {faq.product ? faq.product.name : 'Store'}</small>
                      </div>
                    </div>
                    <div className="col-auto text-end">
                      <div className={"text-end"}><small><FormattedDate month={"short"} day={"2-digit"} year={"numeric"} value={new Date(faq.createdAt)} /></small></div>
                      <small className={'badge bg-success'}>{faq.status}</small>
                    </div>
                  </div>
                  {
                      faq.answer &&
                      <div className="bg-light border p-2 rounded ms-2">
                        {faq.answer}
                      </div>
                  }
                </Link>
              }) :  <EmptyCard />
            }
          </ul>
        }
      </>
  );
}

export default RecentFaqs;
