import useLoyaltyPrograms from '../../pages/loyalty/useLoyaltyPrograms';
import React, {useEffect, useMemo} from 'react';
import Select from '../form/Select';
import ReactSelect from '../form/ReactSelect';

LoyaltyProgramSelector.defaultProps = {
  hasEmpty:true
}
function LoyaltyProgramSelector({value, isMulti, hasEmpty, valuesFilter, onChange}){
  const [programs, load] = useLoyaltyPrograms((state)=>[state.programs, state.load])
  useEffect(()=>{
    if(programs === null){
      load()
    }
  },[load, programs])
  const options = useMemo(()=>{
    return programs ? programs.map((item)=>{
      return {
        label:item.name,
        value: item._id
      }
    }).filter(valuesFilter ? valuesFilter :  (i)=>i) : []
  },[programs, valuesFilter])
  if(isMulti){
    return <ReactSelect closeMenuOnSelect={false}  options={options} value={value} isMulti onChange={onChange}/>
  }else{
    return <Select options={options} hasEmpty={hasEmpty} value={value} onChange={onChange} />
  }
}
export default LoyaltyProgramSelector
