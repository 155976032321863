import React, {useMemo} from 'react';
import {FormattedDate} from 'react-intl';

function RenderDate({value}) {
  const date = useMemo(()=>{
    return value ? new Date(value) : null
  },[value])
  if(!date) return null
  return (
      <FormattedDate month={"short"} day={"2-digit"} year={"numeric"} value={date} />
  );
}

export default RenderDate;
