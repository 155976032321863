import React from 'react';

function SettingsSection({title, subtitle, children}) {
  return (
      <div className={"py-3 border-bottom"}>
        <div className={'row'}>
          <div className="col-md-6">
            <h4>{title}</h4>
            <p className="text-muted">{subtitle}</p>
          </div>
          <div className="col-md-6">
            {children}
          </div>
        </div>
      </div>

  );
}

export default SettingsSection;
