import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Input from '../../components/form/Input';
import TextArea from '../../components/form/TextArea';
import useToggle from '../../components/hooks/useToggle';
import FormGroup from '../../components/form/FormGroup';
import Form from '../../components/form/Form';
import {useDebounce} from '../../components/hooks/useDebounce';
import {useSearchParams} from 'react-router-dom';
import {getHttpClient, gqlQuery} from '../../api';
import Button from '../../components/form/Button';
import RenderRating from './components/RenderRating';
import Select from '../../components/form/Select';
import AsyncCustomerSelector
  from '../../components/customer-selector/AsyncCustomerSelector';
import AsyncProductSelector
  from '../../components/product-selector/AsyncProductSelector';
import {
  FiEdit,
  FiExternalLink,
  FiHeart,
  FiMove,
  FiStar,
  FiTrash,
} from 'react-icons/fi';
import create from 'zustand';
import Skeleton from 'react-loading-skeleton';
import useObjectState from '../../components/hooks/useObjectState';
import Pagination from '../../components/table/Pagination';
import RenderCustomer from './components/RenderCustomer';
import {FormattedDate} from 'react-intl';
import {getThumbnailForImage} from '../../func';
import Modal from '../../components/Modal';
import ProductReviewsImporter from './ReviewImportModal';
import EmptyCard from '../dashboard/components/EmptyCard';
import {useSettingsStore} from '../../store/settingsStore';
import VideoThumbnail from '../../components/video-renderer/VideoThumbnail';
import VideoPlayer from '../../components/video-renderer/VideoPlayer';
import {useForceReload} from '../../components/hooks/hooks';
import ProductReviewsCreator from './ProductReviewsCreator';

const schemaToLoad = `   _id
          title
          description
          rating
          countryCode
          ipAddress
          createdAt
          publishedAt
          reply
          status
          repliedAt
          isFeatured
          attachments {
            _id
            src
            type
            thumbnail
          }
          customer {
            _id
            name
            firstName
            lastName
            emailAddress
            profilePhotoUrl
          }
          product {
            productId
            name
            slug
          }`
const useProductReviews = create((set, get)=>{
  return {
    reviews:[],
    count:0,
    loading:false,
    loadData:({limit, offset, filters})=>{
      set({loading:true})
      return gqlQuery(`query ($limit: Int, $offset:Int, $filter: ReviewFilter){
      reviews(limit: $limit, offset:$offset, filter:$filter){
        data {
            ${schemaToLoad}
        }
        count
        limit
        offset
    }
    }`, {limit, offset,
        filter : {
          productId: filters.product ? String(filters.product.value) : null,
          customerId: filters.customer ? Number(filters.customer.value) : null,
          search: filters.search || null,
          ratings: filters.ratings || null,
          sortBy: filters.sortBy || null,
        }
      }).then(({reviews})=>{
        set({reviews: reviews.data, count: reviews.count, loading:false})
      })
    },
    updateReply:(_id, reply)=>{
      return getHttpClient().post('/admin/reviews/'+_id+'/reply',{
        reply
      }).then(({data})=>{
        set({reviews: get().reviews.map((item)=>{
            return item._id == _id ? {...item, reply} : item
          })})
        return data
      })
    },
    updateReview:(_id, {status, productId, isFeatured})=>{
      return getHttpClient().patch('/admin/reviews/'+_id,{
        status,
        isFeatured,
        productId,
      }).then(({data})=>{
        return  gqlQuery(`query ($reviewId: Int){
        reviewById(reviewId: $reviewId){
          ${schemaToLoad}
        }
       }`, {reviewId: Number(_id)})
        .then(({reviewById})=>{
          set({reviews: get().reviews.map((item)=>{
              return item._id == _id ? reviewById : item
            })})
          return reviewById
        })
      })
    },
    deleteReview:(_id)=>{
      set({reviews: get().reviews.filter((item)=>item._id != _id)})
      return  getHttpClient().delete('/admin/reviews/' + _id)
    },
    deleteAttachment:(_id)=>{
      return getHttpClient()
          .delete('/admin/reviews/attachment/'+_id)
          .then(()=>{
            const rev = get().reviews;
            set({
              reviews: rev.map((item) => {
                return {
                  ...item,
                  attachments: item.attachments.filter((atc)=>{
                    return atc._id !== _id
                  })
                }
              })
            })
          })
    }

  }
})

function ReviewsIndex(props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const limit = Number(searchParams.get('limit') || 10);
  const page = searchParams.get('page')
  const [forceReload, doForceReload] = useForceReload()
  const [filters, setFilters] = useObjectState({})
  const [loadData, loading, reviews, count] = useProductReviews(useCallback((state)=>[state.loadData, state.loading, state.reviews, state.count],[]))
  useEffect(()=>{
    loadData({limit, offset: limit * page, filters})
        .then(()=>{
          console.log('data loaded')
        })
  },[loadData, forceReload, limit, page, filters])
  return (
      <>
        <div className="d-flex align-items-start justify-content-between">
          <div>
            <h2>Product Reviews</h2>
            <p className="text-muted">See what your customers are saying for your products</p>
          </div>
          <div className={"gx-2 d-flex"}>
            <ProductReviewsCreator onChange={doForceReload} />
            <ProductReviewsImporter onChange={doForceReload}/>
          </div>
        </div>
        <ProductReviewsFilters filters={filters} setFilters={setFilters} />

        {
          loading ? <Skeleton count={limit} height={48}/> : <>
            {
              reviews.length > 0 ? <>
                <ul className="list-group mb-2">
                  {
                    reviews.map((item) => {
                      return <ReviewItem key={item._id} review={item}/>
                    })
                  }
                </ul>
                <Pagination loading={loading} count={count} limit={limit}/>
              </> : <EmptyCard/>
            }
          </>
        }
      </>
  );
}

function ProductReviewsFilters({filters, setFilters}){
  const [search, setSearch] = useState(filters.search)
  const _search = useDebounce(search, 300)
  useEffect(()=>{
    setFilters('search')(_search)
  },[setFilters, _search])

  useEffect(()=>{
    setSearch(filters.search)
  },[filters.search])

  return    <div className="bg-light border rounded p-2 mb-2">
    <div className="row">
      <div className="col-md-4">
        <FormGroup label={"Search for review"}>
          <Input type={"search"} value={search} onChange={setSearch} placeholder={"Search..."}/>
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup label={"Filter by customer"}>
          <AsyncCustomerSelector value={filters.customer} onChange={setFilters('customer')} />
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup label={"Filter by product"}>
          <AsyncProductSelector value={filters.product} onChange={setFilters('product')} />
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup label={"Filter by rating"}>
         <RatingPicker value={filters.ratings} onChange={setFilters('ratings')}/>
        </FormGroup>
      </div>
      <div className="col-md-4">
        <FormGroup label={"Sort by"}>
        <Select
            hasEmpty
            options={[
              {
                label:'Newest first',
                value:'createdAtDesc'
              },
              {
                label:"Highest rated",
                value:"ratingDesc"
              },
              {
                label:"Lowest rated",
                value:"ratingAsc"
              },
            ]}
            value={filters.sortBy}
            onChange={setFilters('sortBy')}
            placeholder={"Sort by"}/>
        </FormGroup>
      </div>
    </div>
  </div>
}

function ReviewItem({review}){
  const [visible, setVisible] = useState(false)
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(null)
  const showPhoto = useCallback((idex)=>{
    return ()=>{
      setCurrentPhotoIndex(idex)
      setVisible(true)
    }
  },[])
  return <li className={'list-group-item'}>
    <div className="d-flex border-bottom border-light align-items-center justify-content-between">
      <div>
        <small className={"text-muted"}>by <RenderCustomer customer={review.customer} /></small>
        <small className={"text muted mx-2"}>on</small>
        <small className={"text-muted"}>
          <FormattedDate value={new Date(review.createdAt)} day={"numeric"} month={"short"} year={"numeric"} />
        </small>
      </div>
      <div>
        <RenderStatus status={review.status} />
        <RenderRating rating={review.rating}/>
        <div className={"text-end"}>
          <ViewReviewInStoreLink reviewId={review._id} slug={review.product ? review.product.slug : null} />
        </div>
      </div>
    </div>
   <RenderProduct  review={review} />
    {review.title ? <h6>{review.title}</h6> : null}
    <p className={"pre-line"}>{review.description}</p>
    {
      review.attachments ? <div className={"d-flex flex-wrap gap-2"}>
        {
          review.attachments.map(({_id, type, thumbnail, src}, index)=>{
            if(type === "video"){
              return <button  key={String(_id)}  className="btn p-0" onClick={showPhoto(index)}><VideoThumbnail
                  height={128}
                  width={128}
                 src={thumbnail} /></button>
            }else {
              return <img onClick={showPhoto(index)}
                          key={String(_id)}
                          src={getThumbnailForImage(src,
                      {height: 128, auto_optimize: 'low'})}
                           className={"rounded shadow-sm"}
                          style={{height: 128, objectFit: 'cover'}}
                          alt={"Review photo"}
              />
            }
          })
        }
      </div> : null
    }
    <RenderReply _id={review._id}
                 isFeatured={review.isFeatured}
                 status={review.status} reply={review.reply} repliedAt={review.repliedAt} />
    <ShowMediaModal visible={visible} onClose={()=>setVisible(false)} currentIndex={currentPhotoIndex} attachments={review.attachments} />
  </li>
}

function RenderProduct({review}){
  const [visible, toggle] = useToggle()
  const updateReview = useProductReviews((state)=>state.updateReview)
  const [product, setProduct] = useState({

  })
  useEffect(()=>{
    if(review.product) {
      setProduct({
        label: review.product.name,
        value: review.product.productId
      })
    }
  },[review.product])
  const [saving, setSaving] = useState(false)
  const save = useCallback(()=>{
    setSaving(true)
    updateReview(review._id, {productId: product.value || 'site'})
        .then(()=>{
          setSaving(false)
          toggle()
        })
  },[product.value, review._id, toggle, updateReview])

  return  <><div className="d-inline-flex align-items-center">
    {
      review.product ? <small className={"text-muted"}>{review.product.name}</small> : <>
        <small className={"text-muted"}>Website</small>
      </>
    } <button onClick={toggle} className="btn btn-link btn-sm text-muted"><FiEdit /></button>
  </div>
    { visible ? <>
      <div className="bg-light p-2 rounded border" style={{width:400, maxWidth:'100%'}}>
        <div className="row">
          <div className="col">
            <AsyncProductSelector value={product} onChange={setProduct} />
          </div>
          <div className="col-auto">
            <Button className="btn btn-primary" title={"Submit"} loading={saving} onClick={save}/>
          </div>
        </div>
      </div></> : null
    }
    </>
}

function RenderFeatured({_id, isFeatured}){
  const [f, setF] = useState(false)
  const updateReview = useProductReviews((state)=>state.updateReview)
  useEffect(()=>{
    setF(isFeatured)
  },[isFeatured])
  const toggle = useCallback(()=>{
    setF(!f)
    updateReview(_id, {isFeatured: f ? 0 : 1}).then(r => {
      console.log('update complete')
    })
  },[_id, f, updateReview])
  if(f){
    return <button
        title={"Mark review as not important"}
        onClick={toggle} className={"btn"}><FiHeart style={{color:'#e84393',fill:"#e84393"}} /></button>
  }else{
    return <button
        title={"Mark review as featured"}
        onClick={toggle} className={"btn"}><FiHeart  /></button>
  }
}

function ShowMediaModal({attachments, visible, onClose, currentIndex}){
  const [idx, setIdx] = useState(currentIndex)
  const del = useProductReviews((state)=>state.deleteAttachment)
  useEffect(()=>{
    setIdx(currentIndex)
  },[currentIndex])
  const canPrevious = currentIndex > 0;
  const canNext = attachments ? attachments.length - 1 > currentIndex : false
  const onPrevious = useCallback(()=>{
    setIdx((x)=>x-1)
  },[])
  const onNext = useCallback(()=>{
    setIdx((x)=>{
       return x+1 >= attachments.length ? 0 : x+1
    })
  },[attachments.length])

  useEffect(()=>{
    if(attachments.length === 0){
      onClose()
    }
  },[attachments.length, onClose])

  const deleteAttachment = useCallback(()=>{
    if(window.confirm('Delete attachment from this review ? Caution: This action cannot be undone')){
        del(attachments[idx]._id).then(r => {
         onNext()
        })
    }
  },[attachments, del, idx, onNext])

  return <Modal title={"Media"}
      visible={visible} onClose={onClose} size={"lg"}>
    {
      attachments && attachments.length > 0 && currentIndex !== null &&
      currentIndex > -1 && attachments[idx] ?
          <div className="card-body">
            {
              attachments[idx].type === "video" ?
                  <VideoPlayer src={attachments[idx].src}/>
                  :
                  <img
                      style={{
                        backgroundColor: 'black',
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain'
                      }}
                      src={getThumbnailForImage(attachments[idx].src,
                          {width: 800, auto_optimize: 'low'})}/>
            }
          </div> : null
    }

    <div className="d-flex align-items-center justify-content-between">
      <button onClick={deleteAttachment} className="d-flex align-items-center text-danger btn-link btn" type={"button"}>
        <FiTrash className={"me-2"} />Delete Image
      </button>
      <nav aria-label="Page navigation" className={'d-flex align-items-center'}>
        <ul className="pagination mb-0">
          <li className={`page-item ${!canPrevious ? 'disabled' : ''}`}>
              <button onClick={onPrevious} disabled={!canPrevious} className="page-link">Previous</button>
          </li>
          <li className={`page-item ${!canNext ? 'disabled' : ''}`}>
              <button onClick={onNext} className="page-link">Next</button>
          </li>
        </ul>
      </nav>
    </div>
  </Modal>
}

function RatingPicker({value, onChange}){
  const vals = useMemo(()=>{
    return  (value||'').split(",").map((i)=> i ? Number(i) : null).filter((i)=>i)
  },[value])
  const onClick = useCallback((rating)=>{
    return (e)=>{
      const v = vals.indexOf(rating) > -1 ? vals.filter((i)=>i !== rating) : [rating, ...vals].sort()
      onChange(v.join(","))
    }
  },[vals, onChange])

  return <div>
    <div className="btn-group" role="group" aria-label="Basic example">
    {
      [5,4,3,2,1].map((item)=>{
        const isActive = vals.indexOf(item) > -1
        return <button key={String(item)}
                       onClick={onClick(item)}
                       type="button" className={`${isActive ? 'active':''} btn btn-outline-secondary d-flex align-items-center btn-sm`}>
          <span className={"me-1"}>{item}</span> <FiStar  /></button>
      })
    }

  </div>

  </div>
}

function DeleteBtn({reviewId}){
  const [deleting, setDeleting] = useState(false)
  const deleteReview = useProductReviews((state)=>state.deleteReview)
  function del(){
    if(window.confirm('Delete this review ?')) {
      setDeleting(true)
      deleteReview(reviewId)
          .then(() => {
            setDeleting(false)
        })
    }
  }
  return <Button loading={deleting} onClick={del} className="btn btn-link text-danger"><FiTrash /></Button>

}

function RenderReply({_id, status, reply,isFeatured, repliedAt}){
  const httpUpdateReply = useProductReviews(useCallback((state)=>state.updateReply,[]))

  const [editMode, toggle] = useToggle(false)
  const [data, setData] = useState('')
  const [saving, setSaving] = useState(false)
  useEffect(()=>{
    setData(reply)
  },[reply])
  function save(){
      setSaving(true)
      httpUpdateReply(_id, data).then(({data})=>{
        setSaving(false)
        toggle()
      })

  }
  return  <>
  {
    editMode ?
      <div className={"bg-light p-2 rounded border"}>
        <Form onSubmit={save}>
          <FormGroup label={"Leave a reply"}>
            <TextArea value={data} onChange={setData}/>
          </FormGroup>
          <div className="d-flex">
            <Button loading={saving} disabled={data === (reply||'')} className="btn btn-primary me-2">Submit</Button>
            <button className="btn" onClick={toggle}>Cancel</button>
          </div>
        </Form>
      </div> : <>
          {
              reply &&  <div className={"bg-light p-2 rounded border"}>
                <small className={"d-block"}><FormattedDate value={new Date(repliedAt)} month={"short"} day={"numeric"} /></small>
                {reply}

              </div>
          }
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <RenderFeatured _id={_id} isFeatured={isFeatured} />
              <button className="btn btn-link px-0" onClick={toggle}>{reply ? 'Edit reply' : 'Reply'}</button>
              <RenderPublishButton status={status} _id={_id}/>
            </div>
            <DeleteBtn reviewId={_id} />
          </div>

        </>
    }

  </>
}

function RenderStatus({status}){
  switch (status){
    case "approved":
      return <span className="badge bg-success me-2">approved</span>
    case "pending":
      return <span className="badge bg-warning  me-2">pending</span>
    default: return null
  }
}
function RenderPublishButton({_id, status}){
  const updateReview = useProductReviews((state)=>state.updateReview)
  const [loading, setLoading] = useState(false)
  const onClick = useCallback((newStatus)=>{
    return (e)=>{
      setLoading(true)
      updateReview(_id, {status: newStatus})
          .then(()=>{
            setLoading(false)
          })
    }
  },[_id, updateReview])

  if(status === "approved"){
    return <Button loading={loading} className={"btn btn-link text-warning"} onClick={onClick('pending')}>Un-publish</Button>
  }else if(status === "pending"){
    return <Button  loading={loading} className={"btn btn-link text-success"} onClick={onClick('approved')}>Publish</Button>
  }else{
    return <p>{status}</p>;
  }
}

function ViewReviewInStoreLink({slug, reviewId}){
  const [platform, website] = useSettingsStore(useCallback((state)=>[state.settings.platform, state.settings.website],[]))
  if(!slug) return null;
  if(!reviewId) return null;
  if(platform === "shopify"){
    return <small><a rel={"noopener noreferrer nofollow"}
              className={"text-secondary d-inline-flex align-items-center text-decoration-none"}
              href={`https://${website}/products/${slug}#review_${reviewId}`} target={"_blank"}>View in store
        <FiExternalLink className={"ms-1"} />
    </a>
    </small>
  }
  return null
}

export default ReviewsIndex;
