import React, {useEffect, useRef, useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Overlay, Tooltip} from 'react-bootstrap';
function CopyButton({text, className, title, children}) {
  const [copied, setCopied] = useState(false)
  useEffect(()=>{
    if(copied) {
      const x = setTimeout(() => {
        setCopied(false)
      }, 2000)
      return ()=>clearTimeout(x)
    }
  },[copied])
  const target = useRef(null)
  return (<>
      <CopyToClipboard text={text}
                       onCopy={()=>setCopied(true)}>
        <button ref={target} disabled={copied} className={`btn ${className}`}>{title}{children}</button>

      </CopyToClipboard>
        <Overlay target={target.current} show={copied} placement="top">
          {(props) => (
              <Tooltip  {...props}>
                Copied
              </Tooltip>
          )}
        </Overlay>
    </>
  );
}

export default CopyButton;
