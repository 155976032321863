export const reviewsImportSupportedHeadings = [
  {
    label:'Customer First Name',
    value:'firstName'
  },
  {
    label:'Customer Last Name',
    value:'lastName'
  },
  {
    label:'Customer Name',
    value:'name'
  },
  {
    label:'Customer Email address',
    value:'emailAddress'
  },
  {
    label:'Review title',
    value:'title'
  },
  {
    label:'Review Detail',
    value:'description'
  },
  {
    label:'Rating',
    value:'rating'
  },
  {
    label:'Review Date',
    value:'createdAt'
  },
  {
    label:'Review Date Format',
    value:'date_format',
    isTextInput:true,
  },
  {
    label:'Product ID',
    value:'productId'
  },
  {
    label:'Product Handle',
    value:'productHandle'
  },
  {
    label:'Reply',
    value:'reply'
  },
  {
    label:'Reply Date',
    value:'repliedAt'
  },
  {
    label:'Media URLs',
    value:'attachmentUrls'
  },
  {
    label:'IP Address',
    value:'ip_address'
  },
  {
    label:'Location',
    value:'location'
  }
]

export function reviewsDefaultMap(data){
  const csvHeadings = Object.keys(data[0]).map((item)=>item).filter((i)=>i)
  function oneOf(array){
    return csvHeadings.find((a)=>array.indexOf(a) > -1)
  }
  return {
    name: oneOf(['reviewer_name','nickname']),
    firstName:oneOf(['First Name']),
    lastName:oneOf(['Last Name']),
    emailAddress:oneOf(['reviewer_email','email']),
    productId: oneOf(['product_id','productId']),
    productHandle: oneOf(['product_handle','handle']),
    createdAt: oneOf(['review_date','date','created_at']),
    rating: 'rating',
    title: 'title',
    description: oneOf(['body','review']),
    reply: 'reply',
    repliedAt: oneOf(['reply_date','replied_at']),
    attachmentUrls: oneOf(['img','picture_urls','imageUrl']),
    ipAddress: 'ip_address',
    location: 'location',
  }
}
