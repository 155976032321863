import create from 'zustand';
import {getHttpClient, gqlQuery} from '../../api';

const useLoyaltyRewards = create((set, get)=>{
  return {
    data:[],
    count:null,
    loading:false,
    load:(limit, offset, filter)=>{
      return gqlQuery(`
      query ($limit: Int, $offset: Int, $filter: RewardsFilter){
        rewards (limit: $limit, offset: $offset, filter: $filter){
          data {
            _id
            type
            task_type
            coupon_code
            discountConfig {
              discountType
              discountValue
              freeProduct {
                label
                value
              }
            }
            amount
            status
            order {
              _id
              number
            }
            review {
              _id
              rating
              title
            }
            faq {
             _id
             question
            }
            customer {
             _id
             firstName
             lastName
             emailAddress
             profilePhotoUrl
            }
            createdAt
            availableAt
            expiresAt
            scratchedAt
          }
          count
          limit
          offset
        }
      }
    
    `,{
        limit,
        offset,
        filter:  filter ? {
          taskType : filter.taskType || undefined,
          type: filter.type || undefined,
          customerId: filter.customerId ? filter.customerId.value : undefined,
          dates: filter.dates,
          status: filter.status || undefined,
        } : {}
      }).then(({rewards})=>{
        set({
          data: rewards.data,
          count: rewards.count,
        })
      })
    },
    updateStatus:(_id, newStatus)=>{
      set({data: get().data.map((item)=>{
        return item._id == _id ? {
        ...item,
          status: newStatus
        } : item
      })})
      return getHttpClient().patch('/admin/loyalty/reward/'+_id,{
        status:newStatus
      }).then(({data})=>{

      })
    },
    deleteReward:(_id)=>{
      set({data: get().data.filter((item)=>item._id != _id)})
      return getHttpClient().delete('/admin/loyalty/reward/'+_id)
    }
  }


})
export default useLoyaltyRewards
