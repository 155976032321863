import React, {useCallback} from 'react';
import Select from '../../../components/form/Select';
import {useSettingsStore} from '../../../store/settingsStore';
import {localesOptions} from '../../../supportedLanguages';

function StoreLanguageSelector(props) {
  const defaultLocale = useSettingsStore(useCallback((state)=>state.settings.defaultLocale,[]))
  const updateSettings = useSettingsStore(useCallback((state)=>state.updateSettings,[]))
  const httpUpdate = useCallback((key)=>{
    return val => updateSettings({[key]:val})
  },[updateSettings])
  return (
      <Select options={localesOptions} value={defaultLocale} onChange={httpUpdate('defaultLocale')} />
  );
}

export default StoreLanguageSelector;
